import './css/ControlDashboard.css';
import React, { useState, useEffect, useContext, useRef } from 'react';

import { ctxSession } from '../store';

import Card from '../components/Card';
import Title from '../components/Title';
import CustomInput from '../components/CustomInput';
import IconButton from '@mui/material/IconButton';

import PersonAddIcon from '@mui/icons-material/PersonAdd';

import Fade from '@mui/material/Fade';

import Axios from '../helpers/axios';

import Divider from '@mui/material/Divider';

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarQuickFilter,
  GridRowSelectionModel,
  MuiEvent,
  GridValidRowModel
} from '@mui/x-data-grid';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';


import { taxCodeHTMLRegex } from '../helpers/validator';
import formDataValidator from '../helpers/formData';
import axios, { AxiosError, AxiosResponse } from 'axios';

import CustomTracker from '../components/CustomTracker'
import SimpleDropDownCheck from '../components/SimpleDropDownCheck';
import { Brush, CartesianGrid, Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import moment from 'moment';
import DoctorSelectionPopup from '../components/DoctorSelectionPopup';


const ControlDashboard = () => {

    const session = useContext(ctxSession);

    // Lab registers
    const [labsData, setLabsData] = useState<any>([]);
    useEffect(() => {
        Axios(session, 'post', '/api/lab/GetLaboratoryDetails', (response: any) => {
            const labsWithId = response.data.data.map((lab: any, index: number) => ({
                ...lab,
                // id: index 
            }));
            setLabsData(labsWithId);
        }, { safetyCode: session?.data?.userData.safetyCode });    
    }, [session]);

    // Doctors registers
    const [doctorsData, setDoctorsData] = useState([]);
    useEffect(() => {
        Axios(session, 'post', '/api/doctor/GetDoctorsDetails', (response: any) => {
            const doctorsWithId = response.data.data.map((doctor: any, index: number) => ({
                ...doctor,
                // id: index 
            }));
            setDoctorsData(doctorsWithId);
        }, { safetyCode: session?.data?.userData.safetyCode });    
    }, [session]);

    // Patients registers
    const [patientsData, setPatientsData] = useState([]);
    useEffect(() => {
        Axios(session, 'post', '/api/patient/GetPatientsDetails', (response: any) => {
            const patientsWithId = response.data.data.map((patient: any, index: number) => ({
                ...patient,
                // id: index 
            }));
            setPatientsData(patientsWithId);
        }, { safetyCode: session?.data?.userData.safetyCode });    
    }, [session]);

    // Asociation registers
    const [associationData, setAssociationData] = useState([]);
    useEffect(() => {
        Axios(session, 'post', '/api/doctorPatient/GetAssociationsDetails', (response: any) => {
            const associationsWithId = response.data.data.map((association: any, index: number) => ({
                ...association,
                id: association.id_patient + "." + association.id_doctor 
            }));
            setAssociationData(associationsWithId);
        }, { safetyCode: session?.data?.userData.safetyCode });    
    }, [session]);



    //--------------
    /** Debug */
    useEffect(() => {

        console.log("session status changed")
        console.log(session)

    }, [session])
    //--------------



    
    /** @section Improper Components Section */

    /** TODO: Place these components properly */
    function CustomToolbar() {
        return (
        <GridToolbarContainer style={{ justifyContent: 'flex-end' }}>
            <GridToolbarFilterButton />
            <GridToolbarQuickFilter debounceMs={0}/>
        </GridToolbarContainer>
        );
    }

    function CustomNoRowsOverlay(props:any) {
        return (
          <div style={{ height: '100%', padding: 10, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {props.customText}
          </div>
        );
      }

    function getCustomNoRowsOverlay(message:string) {
        return function() {
            return <CustomNoRowsOverlay customText={message} />;
        };
    }

    const handleEditCellCommit = async (updatedRow:any, endpoint:string) => {
    
        // Update the state
        setLabsData((prev:any) => prev.map((lab:any) => lab.id === updatedRow.id ? updatedRow : lab));

        console.log(labsData)
    
        // Define success and error callbacks
        const onSuccess = (response:any) => {
            console.log('Laboratory updated successfully:', response.data);
        };
        
        // Make the API call with the updated data
        Axios(session, 'post', endpoint, onSuccess, {
            _id: updatedRow.id,
            newData: updatedRow,
            safetyCode: session?.data?.userData.safetyCode
        });
    };
    

    useEffect(()=> {
        console.log(labsData)
    },[labsData])




    return (
        <Fade in={true}>
            <div id="control">
                <main>
                   
                    <Card id="labList"  >
                        <Title  class="mb-0 marginBottomImportant" ><span>Lista LABORATORI E STRUTTURE</span></Title>
                            <DataGrid
                                rows={labsData}
                                editMode='row'
                                columns={[
                                    { field: 'id', headerName: 'ID', editable: true, width:40 },
                                    { field: 'name', headerName: 'Name', editable: true },
                                    { field: 'code', headerName: 'Codice', editable:true , width:50 },
                                    { field: 'type', headerName: 'Tipo', editable:true , width:40 },
                                    { field: 'analysis_endpoint', headerName: 'Analysis Endpoint', editable: true, flex: 1 },
                                    { field: 'emocromo_endpoint', headerName: 'Emocromo Endpoint', editable: true, flex: 1 },
                                    { field: 'vcf_endpoint', headerName: 'VCF Endpoint', editable: true, flex: 1 },
                                ]}
                                processRowUpdate={(updatedRow:GridValidRowModel, originalRow:GridValidRowModel) =>{
                                    handleEditCellCommit(updatedRow, '/api/lab/updateLaboratory');
                                    return updatedRow;
                                }}
                                slots={{
                                    toolbar: CustomToolbar,
                                }}
                            />    

                    </Card>

                    <Card id="patientList"  >
                        <Title  class="mb-0 marginBottomImportant" ><span>Lista PAZIENTI</span></Title>
                        <DataGrid
                                rows={patientsData}
                                editMode='row'
                                columns={[
                                    { field: 'id', headerName: 'ID', editable: true, width:40 },
                                    { field: 'firstname', headerName: 'Nome', editable: true, width:100 },
                                    { field: 'lastname', headerName: 'Cognome', editable:true , width:100 },
                                    { field: 'email', headerName: 'Email', editable:true , width:100 },
                                    { field: 'analysis_endpoint', headerName: 'Analysis Endpoint', editable: true, flex: 1 },
                                    { field: 'emocromo_endpoint', headerName: 'Emocromo Endpoint', editable: true, flex: 1 },
                                    { field: 'vcf_endpoint', headerName: 'VCF Endpoint', editable: true, flex: 1 },
                                ]}
                                processRowUpdate={(updatedRow:GridValidRowModel, originalRow:GridValidRowModel) =>{
                                    handleEditCellCommit(updatedRow, '/api/patient/updatePatient');
                                    return updatedRow;
                                }}
                                slots={{
                                    toolbar: CustomToolbar,
                                }}
                            /> 

                    </Card>

                    <Card id="medicList"  >
                        <Title  class="mb-0 marginBottomImportant" ><span>Lista MEDICI</span></Title>
                        <DataGrid
                                rows={doctorsData}
                                editMode='row'
                                columns={[
                                    { field: 'id', headerName: 'ID', editable: true, width:40 },
                                    { field: 'firstname', headerName: 'Nome', editable: true, width:100 },
                                    { field: 'lastname', headerName: 'Cognome', editable:true , width:100 },
                                    { field: 'provincial_order', headerName: 'Ordine', editable:true , width:100 },
                                    { field: 'analysis_endpoint', headerName: 'Analysis Endpoint', editable: true, flex: 1 },
                                    { field: 'emocromo_endpoint', headerName: 'Emocromo Endpoint', editable: true, flex: 1 },
                                    { field: 'vcf_endpoint', headerName: 'VCF Endpoint', editable: true, flex: 1 },
                                ]}
                                processRowUpdate={(updatedRow:GridValidRowModel, originalRow:GridValidRowModel) =>{
                                    handleEditCellCommit(updatedRow, '/api/doctor/updateDoctor');
                                    return updatedRow;
                                }}
                                slots={{
                                    toolbar: CustomToolbar,
                                }}
                            /> 

                    </Card>

                    <Card id="associationList"  >
                        <Title  class="mb-0 marginBottomImportant" ><span>Lista ASSOCIAZIONI</span></Title>
                        <DataGrid
                                rows={associationData}
                                editMode='row'
                                columns={[
                                    { field: 'id', headerName: 'ID', editable: true, width:40 },
                                    { field: 'id_patient', headerName: 'ID Paziente', editable: true, flex: 1 },
                                    { field: 'id_doctor', headerName: 'ID Medico', editable:true, flex: 1  },
                                    { field: 'status', headerName: 'Status', editable:true, flex: 1 },
                                ]}
                                processRowUpdate={(updatedRow:GridValidRowModel, originalRow:GridValidRowModel) =>{
                                    handleEditCellCommit(updatedRow, '/api/doctorPatient/updateAssociation');
                                    return updatedRow;
                                }}
                                slots={{
                                    toolbar: CustomToolbar,
                                }}
                            /> 

                    </Card>

                    
                        
                </main>
            </div>
        </Fade>
    );
};
export default React.memo(ControlDashboard);


// http://localhost:3001/