import './css/Title.css';


import React from 'react';

import { Box } from '@mui/material';


function Card (props: any) {
    
    let className = "title";
    if(props.class){
        className = className + ' ' + props.class;
    }


    // console.log(props)
    let childrens = null;
    if( props.children && Array.isArray(props.children) ){
        childrens = props.children.map((component: any, i: number) => component)
    }else{
        childrens = props.children
    }
    // console.log(childrens)
    return (
        <h5 className={className}>
            {
                childrens
            }
        </h5>
    );
}

export default React.memo(Card);