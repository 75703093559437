
import axios from 'axios';


// (method:string, url:string, params:object, then : (...args: any[]) => void) => {
const Catch = (error, auth) => {
  // console.log(auth)
  switch(error.response.status){
    case 401:{
      localStorage.removeItem('token');
      if(auth.data && auth.set){
        
        auth.set(null)
      }
      break;
    }
    case 451: {
      break;
    }
    default: {
      break;
    }
  }
}  

const Axios = (auth, method, url, then, data = {}, config = {}) => {
  // console.log(auth)

  let token = localStorage.getItem('token');
  if(token){
    config = {
      ...config,
      headers: {
        ...config.header,
        'Authorization': `Bearer ${token}`
      }
    }
  }


  const request = axios[method];
  switch(method){
    //   get<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: RawAxiosRequestConfig<D>): Promise<R>;
    case 'get':{
      request(url, {...config})
        .then((result) => result.status !== 204 ? then(result) : console.log('204 : No Content'))
        .catch(error => Catch(error, auth));
      break;
    }
    //   post<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: RawAxiosRequestConfig<D>): Promise<R>;
    case 'post':{
      request(url, {...data}, {...config})
        .then((result) => result.status !== 204 ? then(result) : console.log('204 : No Content'))
        .catch(error => Catch(error, auth));
      break;
    }
    //   put<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: RawAxiosRequestConfig<D>): Promise<R>;
    case 'put':{
      request(url, {...data}, {...config})
        .then((result) => result.status !== 204 ? then(result) : console.log('204 : No Content'))
        .catch(error => Catch(error, auth));
      break;
    }
    //   patch<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: RawAxiosRequestConfig<D>): Promise<R>;
    case 'patch':{
      request(url, {...data}, {...config})
        .then((result) => result.status !== 204 ? then(result) : console.log('204 : No Content'))
        .catch(error => Catch(error, auth));
      break;
    }
    //   delete<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: RawAxiosRequestConfig<D>): Promise<R>;
    case 'delete':{
      request(url, {...config})
        .then((result) => result.status !== 204 ? then(result) : console.log('204 : No Content'))
        .catch(error => Catch(error, auth));
      break;
    }
    default: {
      console.error('Bad method')
      break;
    }
  }

}

export default Axios;


// export class Axios {
//   constructor(config?: RawAxiosRequestConfig);
//   defaults: AxiosDefaults;
//   interceptors: {
//     request: AxiosInterceptorManager<AxiosRequestConfig>;
//     response: AxiosInterceptorManager<AxiosResponse>;
//   };
//   getUri(config?: RawAxiosRequestConfig): string;
//   request<T = any, R = AxiosResponse<T>, D = any>(config: RawAxiosRequestConfig<D>): Promise<R>;
//   head<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: RawAxiosRequestConfig<D>): Promise<R>;
//   options<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: RawAxiosRequestConfig<D>): Promise<R>;
//   postForm<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: RawAxiosRequestConfig<D>): Promise<R>;
//   putForm<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: RawAxiosRequestConfig<D>): Promise<R>;
//   patchForm<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: RawAxiosRequestConfig<D>): Promise<R>;
// }
