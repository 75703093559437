import React, { useState, useEffect, useRef } from 'react';
import './css/CustomTracker.css'

import InitializedIcon from '@mui/icons-material/BookmarkAdded';
import StoredIcon from '@mui/icons-material/Archive';
import TransportIcon from '@mui/icons-material/AirportShuttle';
import LabIcon from '@mui/icons-material/Science';
import AnalysisIcon from '@mui/icons-material/Troubleshoot';
import VerifiedIcon from '@mui/icons-material/Check';


function CustomTracker(props:any){

    /** Props */

    // Hardcoded parameters. If this component has to go in production those will become props
    const circlesAmount = 5;
    const circleRadius = 5;
    const trackingContainerWidth = 300;
    const svgHeight = circleRadius * 3; // Adjusted for better visibility
    const centerY = svgHeight / 2; // Center of the SVG for the y-axis


    /** Design */

    // Calculate the gap space between the circles
    const gapBetweenCircles = (trackingContainerWidth - (2 * circleRadius * circlesAmount)) / (circlesAmount - 1);

    // Load the icon sequence
    const iconComponents = [InitializedIcon, TransportIcon, StoredIcon, LabIcon, AnalysisIcon];


    /** Elaboration */

    // SVG register
    const circles = [];
    const lines = [];
    const messages = [
        "Selezionare un Esame con un Campione associato..",
        "Il Campione è stato Inizializzato",
        "Il Campione è in fase di Trasporto",
        "Il Campione è arrivato in Laboratorio",
        "Il Campione è attualmente in fase di Analisi",
        "Il Campione è stato Analizzato"
    ]

    // Elaborate SVG component properties
    for (let i = 0; i < circlesAmount; i++) {
        const cx = circleRadius + i * (2 * circleRadius + gapBetweenCircles);
        const isVerified = i < props.trackingStatus - 1;
        const isChecked = i === props.trackingStatus - 1;

        const fill = isVerified || isChecked ? 'var(--blue-de-france)' : 'var(--gray-400)';
        const IconComponent = isVerified ? VerifiedIcon : iconComponents[i];
        
        circles.push(
            <g key={`circle-group-${i}`}>
                <svg style={{ height: 0 }}>
                    <defs>
                        <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
                            <feDropShadow dx="0" dy="0" stdDeviation="5" floodColor="var(--blue-de-france)" />
                        </filter>
                    </defs>
                </svg>
                <circle cx={cx} cy={centerY} r={isChecked ? circleRadius * 2 : circleRadius} fill={fill} filter={isChecked ? "url(#shadow)" : ""}/>
                {(isChecked) && 
                            <g transform={`translate(${cx - (circleRadius * 30)}, 0)`}>
                                <IconComponent style={{ color: 'var(--blue-de-france)', fill: 'white', position: 'absolute'}} />
                            </g>}
                {(isVerified) && (
                    <g transform={`translate(${cx - circleRadius}, ${centerY - circleRadius})`}>
                        <VerifiedIcon style={{ color: 'var(--blue-de-france)', fill: 'white'}} width={10} height={10} />
                    </g>
                )}
            </g>
        );

        if (i < circlesAmount - 1) {
            const nextCx = circleRadius + (i + 1) * (2 * circleRadius + gapBetweenCircles);
            const lineStroke = (isVerified || i + 1 === props.trackingStatus - 1) ? 'var(--blue-de-france)' : 'var(--gray-300)';
            lines.push(
                <line
                    x1={cx + (isChecked ? circleRadius * 2 : circleRadius)}
                    y1={centerY}
                    x2={nextCx - circleRadius}
                    y2={centerY}
                    stroke={lineStroke}
                    strokeWidth="2"
                    key={`line-${i}`}
                />
            );
        }
    }

    return (
        <div className="trackerContainer">
            <div className="trackingContainer" > 
                <p style={{ marginBottom: props.trackingStatus !== 0 ? '15px' : '-15px', marginTop: '0', transition:'0.3s all'}}>{messages[props.trackingStatus]}</p>
                <svg width={trackingContainerWidth} height={svgHeight} style={{overflow:'visible', opacity: props.trackingStatus !== 0 ? 1 : 0, visibility: props.trackingStatus !== 0 ? 'visible' : 'hidden', transition: 'opacity 0.8s, visibility 0.5s'}}>
                    {lines}
                    {circles}
                </svg>
            </div>
        </div>
    );
}

export default CustomTracker;
