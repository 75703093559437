import './css/Auth.css';
import React from 'react';

import { BrowserRouter as Router, Route, Routes, Navigate  } from 'react-router-dom'


import LogIn from './LogIn';
import ForgotPassword from './ForgotPassword';
import SignUp from './SignUp';

// import importer from '../helpers/importer'

const Auth = () => {
    
    return (
        <div id="Auth" >
            <div className={"h-100 " + "container"}>
                <Routes>
                    <Route path="/" element={<LogIn /*login = {props.login}*/ />} />
                    <Route path="/ForgotPassword" element={<ForgotPassword />} />
                    <Route path="/SignUp" element={<SignUp />} />
                    <Route
                        path="*"
                        element={<Navigate to="/" />}
                    />
                </Routes>
            </div>
        </div >
    );
};
export default React.memo(Auth);