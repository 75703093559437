const formDataValidator = (eTarget, validators = []) => {
    const formData = new FormData(eTarget)

    const formDataObj = {}


    
    formData.forEach((v,k) => {
        if(typeof v === 'string'){
            v = v.trim()
        }
        formDataObj[k] = v;
    })


    return formDataObj;
}

export default formDataValidator;