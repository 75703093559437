import './css/CustomButton.css';


import React from 'react';

import { Tooltip } from '@mui/material';


function CustomButton (props: any) {
    // console.log(props)


    let className = "btn ";
    switch(props.variant){
        case "primary": {
            className += 'btn-primary ';
            break;
        }
        case "secondary": {
            className += 'btn-secondary ';
            break;
        }
        default: {
            className += 'btn-primary ';
            break;
        }
    }
    
    switch(props.size){
        case 'small':
        case "sm": {
            className += 'btn-sm';
            break;
        }
        case 'large':
        case "lg": {
            className += 'btn-lg';
            break;
        }
    }

    
    if(props.class){
        className = className + ' ' + props.class;
    }


    // console.log( props )
    let childrens = null;
    if( props.children && Array.isArray(props.children) ){
        childrens = props.children.map((component: any, i: number) => component)
    }else{
        childrens = props.children
    }

    console.log(props)

    return (
        <Tooltip placement={props.placement} title={props.title}>
            <button
            onClick={props.onClick || ( () => { console.log('onClick') } )}
            id={props.id}
            className={className}
            type={props.type ? props.type : 'button'}>
            {
                childrens   // props.children && props.children.map((component: any, i: number) => component)
            }
            </button>
        </Tooltip>
    );
}

export default React.memo(CustomButton);