import './css/DoctorDashboard.css';
import React, { useState, useEffect, useContext, useRef } from 'react';

import { ctxSession } from '../store';

import Card from '../components/Card';
import Title from '../components/Title';
import CustomInput from '../components/CustomInput';
import IconButton from '@mui/material/IconButton';

import PersonAddIcon from '@mui/icons-material/PersonAdd';

import Fade from '@mui/material/Fade';

import Axios from '../helpers/axios';

import Divider from '@mui/material/Divider';

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarQuickFilter,
  GridRowSelectionModel
} from '@mui/x-data-grid';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';


import { taxCodeHTMLRegex } from '../helpers/validator';
import formDataValidator from '../helpers/formData';
import { AxiosError, AxiosResponse } from 'axios';

import CustomTracker from '../components/CustomTracker'
import SimpleDropDownCheck from '../components/SimpleDropDownCheck';
import { Brush, CartesianGrid, Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import moment from 'moment';
import CustomBarChart from '../components/CustomBarChart';


const DoctorDashboard = () => {

    const session = useContext(ctxSession);

    // Lab registers
    const [startChartDropdownPosition, setStartChartDropdownPosition] = useState<{ top: number, left: number } | null>(null);
    const [isChartDropdownVisible, seIsChartDropdownVisible] = useState<boolean>(false);
    const [currentMedicName, setCurrentMedicName] = useState<any>(session?.data?.userData.name);
    const [startExamDropdownPosition, setStartExamDropdownPosition] = useState<{ top: number, left: number } | null>(null);
    const [isExamDropdownVisible, seIsExamDropdownVisible] = useState<boolean>(false);

    // Patients register
    const [patients, setPatients] = useState<any>([]);
    const selectedPatientRef = useRef<any>(null);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);

    // Analysis register
    const [currentAnalysisList, setCurrentAnalysisList]  = useState<any>([]);

    // Emocromo register
    const [currentEmocromoList, setCurrentEmocromoList]  = useState<any>([]);

    // VCF register
    const [currentVCF, setCurrentVCF] = useState<any>([]);

    // Tracking register
    const [currentTrackingStatus, setCurrentTrackingStatus]  = useState<any>([]);

    // Combined data register
    const [combinedAnalysisData, setCombinedAnalysisData] = useState<any>([]);

    // Room register
    const [selectedExamRoom, setSelectedExamRoom] = useState<any>([]);
    const [selectedSubLab, setSelectedSubLab] = useState<any>([]);

    // InstrumentData register
    const [currentInstrumentData, setCurrentInstrumentData] = useState<any>([])
    const [selectedInstrumentType, setSelectedInstrumentType] = useState<any>(null);
    const [selectedInstrumentUOM, setSelectedInstrumentUOM] = useState<any>(null);

    // ExpandedAnalysisView register
    const [isExpandedAnalysisView, setIsExpandedAnalysisView] = useState<any>(null);
    const [currentExpandedAnalysisState, setCurrentExpandedAnalysisState] = useState<any>(null);
    const renderCellFunctionExpandedAnalysis = (params:any) => {
        const isObject = typeof params.row.risultato === 'object' && params.row.risultato !== null;
        const displayValue = isObject 
            ? <strong 
                className="customExpandResult" 
                onMouseOver={() => {setCurrentExpandedAnalysisState(params.row); setIsExpandedAnalysisView(true)}}
                onMouseLeave={() => setIsExpandedAnalysisView(false)}
                >
                ESPANDI RISULTATO
                </strong> 
            : <span className="customResultCell">{params.row.risultato + " " + params.row.unita_misura}</span>;
    
        return <>{displayValue}</>;
    };

    // SelectedAnalysisRegister
    const [selectedAnalysis, setSelectedAnalysis] = useState<any>(null);
    const [selectedAnalysisId, setSelectedAnalysisId] = useState<any>(null);
    const [selectedAnalysisType, setSelectedAnalysisType] = useState<any>(null);
    const [chartData, setChartData] = useState<any>({
        chartData: [],
        uom: null,
        minMax: [null, null]
      });
          

    // Chart type selector
    const [currentChartType, setCurrentChartType]  = useState<any>("GRAFICO ESAME");
    const [currentExamType, setCurrentExamType]  = useState<any>("LISTA ESAMI LABORATORIO");


    // Chart register
    // Sample data
    const SampleData = [
        { timestamp: '2022-01-01T12:00:00', value: 10 },
        { timestamp: '2022-01-02T12:00:00', value: 15 },
        { timestamp: '2022-01-03T12:00:00', value: 10 },
    ];
    const examTimestamp = '2022-01-02T12:00:00'; // Example exam timestamp

    // VCF register
    const [currentExpandedVCF, setCurrentExpandedVCF] = useState<any>(null);
    const [isExpandedVCFView, setIsExpandedVCFView] = useState<any>(null);
    const renderCellFunctionExpandedVCF = (params:any) => {
        const isObject = typeof params.row.vcf === 'object' && params.row.vcf !== null;
        const displayValue = isObject 
            ? <strong 
                className="customExpandResult" 
                onClick={() => {!isExpandedVCFView ? setCurrentExpandedVCF(params.row) : setCurrentExpandedVCF(null); setIsExpandedVCFView(!isExpandedVCFView)}}
                >
                ESPANDI RISULTATO
                </strong> 
            : <span className="customResultCell">NESSUN RISULTATO</span>;
    
        return <>{displayValue}</>;
    };

    // Loading register
    const [isAnalysisLoading, setIsAnalysisLoading] = useState<any>(null);


    // Function to format timestamp
    const formatTimestamp = (timestamp:any) => {
        return moment(timestamp).format('YYYY-MM-DD HH:mm');
    };

    /** System utilities */

    // Reset Global States
    // Reset Global States
    function resetAllStates(){
        setCurrentAnalysisList(null);
        setCurrentEmocromoList(null);
        setCurrentTrackingStatus(null);
        setCombinedAnalysisData(null);
        setSelectedExamRoom(null);
        setSelectedSubLab(null);
        selectedPatientRef.current = null;
        setSelectionModel([]);
        setCurrentInstrumentData(null);
        setSelectedInstrumentType(null);
        setSelectedInstrumentUOM(null);
        setIsExpandedAnalysisView(null);
        setSelectedAnalysis(null);
        setCurrentVCF(null);
    }

    // Reset InstrumentModel state whenever the currentAnalysisList changes
    useEffect(() => {

        setSelectedInstrumentType(null);
        setSelectedInstrumentUOM(null);

    }, [currentAnalysisList, selectedExamRoom])

    // Reset TrackingStatus whenever the InstrumentData changes
    useEffect(() => {

        setCurrentTrackingStatus(null);

    }, [currentInstrumentData])

    /** @section Fetching */

    // UserAccount filling
    useEffect(() => {

        setCurrentMedicName(session?.data?.userData.firstname + " " + session?.data?.userData.lastname);

    }, [session?.data])

    /** Fetch patients for the logged User */
    useEffect(( ) => {

        const onSuccess = (response:AxiosResponse) => {
            if( response.data ){

                /** Set the "id" field in the Response for DataGrid to work */
                // Map over AnalysisList and add a unique id to each item
                response.data.data = response.data.data.map((item:any, index:number) => ({
                    ...item,
                    id: index,
                }));

                /** @warning Type and Format check is required here when the shape is final */
                setPatients( response.data )
                console.log("Patients fetched: ", response.data)

            }
        }

        const onError = (error:AxiosError) => {
            console.error("Error getting Patients: ", error.message)
        }

        resetAllStates();

        Axios(session, 'get', `/api/doctor/${session?.data?.userData?.id}/patient`, onSuccess, onError);

    }, []);

    /** Fetch AnalysisList for the SelectedPatient */
    const fetchAnalysisList = (taxCode:string, startDate?:Date | null, endDate?:Date | null, analysisType?:string) => {

        const onSuccess = (response:AxiosResponse) => {

            if( response.data ){
                
                /** @warning Type and Format check is required here when the shape is final */

                // Get current patient ID
                const currentPatient = patients.data.find((patient: any) => patient.tax_code === taxCode);

                /** Set the "id" field in the Response for DataGrid to work */
                // Transform the vcf array into a format suitable for DataGrid
                response.data.data.VCFList = response.data.data.VCFList.map((vcfAnalysis:any, index:any) => ({
                    id: index, // Unique id for each row
                    ...vcfAnalysis
                }));

                console.log(response.data.data.VCFList)

                setCurrentAnalysisList(response.data.data.AnalysisList);
                setCurrentEmocromoList(response.data.data.EmocromoList);
                setCurrentVCF(response.data.data.VCFList)
                console.log("Analysis fetched: ", response.data);
                setIsAnalysisLoading(false)

                // Set current Patient as Selected
                selectedPatientRef.current = currentPatient;
                
            }
        }

        const onError = (error:AxiosError) => {
            console.error("Error getting AnalysisList: ", error.message)
            setIsAnalysisLoading(false)
        }

        // Reset states
        resetAllStates();
        setIsAnalysisLoading(true);

        Axios(session, 'get', `/api/analysis/GetAnalysisListFromTaxCodeMedic/${taxCode}/${session?.data?.userData?.id}`, onSuccess, onError);

    }

     /** Fetch TrackingStatus for the SelectedPatient */
    const fetchTrackingStatus = (taxCode:string, tagID:string) => {

        /// Early return if taxCode or tagID are null
        if(!taxCode || !tagID) return;

        const onSuccess = (response:AxiosResponse) => {

            if( response.data ){
                
                /** @warning Type and Format check is required here when the shape is final */

                /** Set the "id" field in the Response for DataGrid to work */
                // Map over TrackinStatus.HistoricalData and add a unique id to each item
                response.data.data.historicalData = response.data.data.historicalData.map((item:any, index:number) => ({
                    ...item,
                    id: index,
                }));

                setCurrentTrackingStatus(response.data);
                console.log("Tracking fetched: ", response.data);
                
            }
        }

        const onError = (error:AxiosError) => {
            console.error("Error getting TrackingStatus: ", error.message)
        }

        // Reset tracking status
        setCurrentTrackingStatus(null);

        Axios(session, 'get', `/api/tag/GetTrackingStatusFromTagIdMedic/${taxCode}/${session?.data?.userData?.id}/${tagID}`, onSuccess, onError);

    }

    /** Fetch TrackingStatus for the SelectedPatient */
    const fetchInstrumentData = (roomID:string, labID:string) => {

        /// Early return if taxCode or roomID are null
        if(!roomID) return;

        const onSuccess = (response:AxiosResponse) => {

            if( response.data ){
                
                /** @warning Type and Format check is required here when the shape is final */

                setCurrentInstrumentData(response.data);
                console.log("InstrumentData fetched: ", response.data);
                
            }
        }

        const onError = (error:AxiosError) => {
            console.error("Error getting InstrumentData: ", error.message)
        }

        // Reset instrument status
        setCurrentInstrumentData(null);

        Axios(session, 'get', `/api/environment/GetInstrumentsQueryParamsMedic/${session?.data?.userData?.id}/${labID}/${roomID}`, onSuccess, onError);

    }


    /** @section Combining */

    /** Combine AnalysisList and EmocromoList */
    const combineAnalysisAndPredictionData = (analysisList:any, EmocromoList:any) => {

        // Early return if EmocromoList is empty
        if (!EmocromoList || EmocromoList.length === 0) {
            return analysisList?.map((item:any, index:number) => ({
                    ...item,
                    id: index,
                }));
        }

        console.log("Analysis List:", analysisList);
        console.log("Emocromo List:", EmocromoList);

        // Merge the two arrays
        const mergedArray = [...analysisList, ...EmocromoList];

        // Create a set to track unique id_tags
        const uniqueTags = new Set();

        // Filter out duplicates based on id_tag and assign unique ids
        const filteredMergedArray = mergedArray.filter((item: any, index: number) => {
            if (item.id_tag && uniqueTags.has(item.id_tag)) {
                return false; // Exclude duplicate
            }
            uniqueTags.add(item.id_tag);
            item.id = index; // Assigning a unique id
            return true;  // Include unique item
        });

        return filteredMergedArray;

    };

    /** Combine AnalysisList and EmocromoList */
    useEffect(() => {

        // Combine AnalysisList and EmocromoList
        const combinedData = combineAnalysisAndPredictionData(currentAnalysisList, currentEmocromoList);
        setCombinedAnalysisData(combinedData); // Update state with combined data

    }, [currentEmocromoList])


    //--------------
    /** Debug */
    useEffect(() => {

        console.log("combinedAnalysisData status changed")
        console.log(combinedAnalysisData)

    }, [combinedAnalysisData])
    //--------------



    /** @section Charting */

    const extractMinMax = (valoriNormali:any) => {
        let min = null, max = null;

        // Check for range format (e.g., "74 - 106")
        const rangeMatch = valoriNormali.match(/(\d+(?:[.,]\d+)?)\s*-\s*(\d+(?:[.,]\d+)?)/);
        if (rangeMatch) {
          min = parseFloat(rangeMatch[1].replace(',', '.'));
          max = parseFloat(rangeMatch[2].replace(',', '.'));
        }
        // Check for upper limit format (e.g., "< 5.0 ng/ml")
        else if (valoriNormali.includes('<')) {
          const upperLimitMatch = valoriNormali.match(/<\s*(\d+(?:[.,]\d+)?)/);
          if (upperLimitMatch) {
            max = parseFloat(upperLimitMatch[1].replace(',', '.'));
          }
        }
      
        return [min, max];
    };
    

    const processChartData = (analysisList:any, selectedAnalysisType:any) => {
        if (!selectedAnalysisType || analysisList.length === 0) {
            return { chartData: [], uom: null, minMax: [null, null] };
          }
      
        const filteredData = analysisList.filter((analysis:any) => analysis.esame === selectedAnalysisType);
        const uom = filteredData[0]?.unita_misura; // Assuming the first record's unit is the standard
      
        const chartData = filteredData.map((analysis:any, index:number) => ({
          id: analysis.id,
          value: parseFloat(analysis.risultato.replace(',', '.')), // Convert to float, handle comma as decimal
          label: `Esame ${index + 1}`,
          isSelected: analysis.id === selectedAnalysisId,
        }));
      
        const minMax = extractMinMax(filteredData[0]?.valori_normali);
        console.log(minMax)
      
        return { chartData, uom, minMax };
    };

    // Call this function when an analysis type is clicked
    const onAnalysisTypeClick = (analysisType:any) => {
        setSelectedAnalysisType(analysisType);
        const processedData = processChartData(currentAnalysisList, analysisType); 
        console.log(processedData)
        setChartData({
          chartData: processedData.chartData,
          uom: processedData.uom,
          minMax: processedData.minMax
        });
    };


    /** @section Handlers */

    const handleChartClick = (clickEvent:React.MouseEvent) => {

        // Set the GroupFilterDropdown position
        const icon = clickEvent.target as HTMLElement;
        const boundingRect = icon.getBoundingClientRect();
        setStartChartDropdownPosition({
            top: boundingRect.bottom,
            left: boundingRect.left -1
        });

        // Show Dropdown
        seIsChartDropdownVisible(true);

    }

    const handleChartSelection = (chart:any) => {

        // Set current SubLab
        if (session?.data?.userData){

            setCurrentChartType(chart);
        } 

        // Hide Dropdown
        seIsChartDropdownVisible(false);

    }

    const handleExamClick = (clickEvent:React.MouseEvent) => {

        // Set the GroupFilterDropdown position
        const icon = clickEvent.target as HTMLElement;
        const boundingRect = icon.getBoundingClientRect();
        setStartExamDropdownPosition({
            top: boundingRect.bottom,
            left: boundingRect.left -1
        });

        // Show Dropdown
        seIsExamDropdownVisible(true);

    }

    const handleExamSelection = (chart:any) => {

        // Set current SubLab
        if (session?.data?.userData){

            setCurrentExamType(chart);
        } 

        // Hide Dropdown
        seIsExamDropdownVisible(false);

    }

    
    function CustomToolbar() {
        return (
        <GridToolbarContainer style={{ justifyContent: 'flex-end' }}>
            <GridToolbarFilterButton />
            <GridToolbarQuickFilter debounceMs={0}/>
        </GridToolbarContainer>
        );
    }

    function CustomNoRowsOverlay(props:any) {
        return (
          <div style={{ height: '100%', padding: 10, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {props.customText}
          </div>
        );
      }

    function getCustomNoRowsOverlay(message:string) {
        return function() {
            return <CustomNoRowsOverlay customText={message} />;
        };
    }
  

    const invitePatient = (e:React.FormEvent<EventTarget>) => {
        e.preventDefault();

        
        const formData = formDataValidator(e.target);
        console.log(formData)
    }


    return (
        <Fade in={true}>
            <div id="doctor">
                <main>
                    <Card  id="patientsList">

                        {/* <Title  class="mb-0">Aggiungi Paziente</Title>
                            <form id="addPatient" onSubmit={invitePatient}>
                                <CustomInput
                                    type="text"
                                    name="tax_code"
                                    variant="cloud"
                                    pattern={taxCodeHTMLRegex}
                                    placeholder="Codice Fiscale"
                                    class="mb-2"
                                    required>
                                    
                                </CustomInput>
                                
                                <IconButton type="submit" size="small" onClick={() => true}>
                                    <PersonAddIcon/>
                                </IconButton>
                            </form> */}

                        {/* <Divider /> */}

                        <Title  class="mb-0 marginBottomImportant">Lista Pazienti {currentMedicName && <span style={{color:"var(--blue-de-france"}}>MEDICO {currentMedicName.toUpperCase()}</span>}</Title>
                            <DataGrid
                            onRowClick={(params) => {
                                fetchAnalysisList(params.row.tax_code)
                                isExpandedVCFView && setCurrentExpandedVCF(null); isExpandedVCFView && setIsExpandedVCFView(false)
                                setChartData({
                                    chartData: [],
                                    uom: null,
                                    minMax: [null, null]
                                  });
                            }}
                            rowSelectionModel={selectionModel}
                            onRowSelectionModelChange={(newSelection:GridRowSelectionModel) => setSelectionModel(newSelection)}
                            sx={
                                    {
                                        '& .MuiDataGrid-columnHeaders': {
                                            backgroundColor: '#f6f7fb'
                                        },
                                        '& .MuiDataGrid-cell--editing': {
                                            outline: 'none'  // Remove outline from cell when editing
                                        },
                                        '& .MuiDataGrid-cell:focus': {
                                            outline: 'none'  // Remove focus outline from cell
                                        },
                                        '& .MuiDataGrid-columnHeaderTitle': {
                                            fontWeight: 'bold',
                                            textAlign: 'center',
                                            flex: 1
                                        },
                                        '& .MuiDataGrid-columnHeaderTitleContainer': {
                                            justifyContent: 'center', 
                                        },
                                        '& .MuiDataGrid-cell': {
                                            textAlign: 'center', 
                                            justifyContent: 'center',
                                            display: 'flex', 
                                            alignItems: 'center', 
                                        }
                                    }
                                }
                                rowHeight={30}
                                columns={[
                                    { field: 'id', headerName: 'ID', width: 60},
                                    {
                                        field: 'nome',
                                        headerName: 'Paziente',
                                        minWidth: 150,
                                        renderCell: ({row}) => {
                                            return `${row.firstname} ${row.lastname}`
                                        }
                                    },
                                    { field: 'tax_code', headerName: 'CF', minWidth: 160 },
                                    { field: 'date_of_birth', headerName: 'Data di Nascita', minWidth: 120,         
                                        valueFormatter: (params) => {
                                            // Format the date to exclude milliseconds
                                            return moment(params.value).format('YYYY-MM-DD');
                                        }
                                     },
                                    { field: 'sex', headerName: 'Sesso', flex: 1},
                                ]}
                                rows={patients.data ?? []}
                                slots={{
                                    toolbar: CustomToolbar,
                                }}

                                // onRowSelectionModelChange={(newRowSelectionModel) => {
                                //     console.log(data, newRowSelectionModel)
                                //     const test = data.rows.filter((tuple) => tuple.id === newRowSelectionModel[0])
                                //     console.log(test)
                                // }}
                            />

                    </Card>
 

                    {/** EXPANDED EMOCROMO ANALYSIS AREA */}
                    <Card id={isExpandedAnalysisView ? "expandedAnalysisModalVisible" : "expandedAnalysisModalHidden"}  >
                        <Title  class="mb-0 marginBottomImportantModal ">Dettaglio Analisi {currentExpandedAnalysisState && <span style={{color:"var(--blue-de-france)"}}>
                            {currentExpandedAnalysisState?.esame?.toUpperCase() + " " + currentExpandedAnalysisState?.data_prelievo}</span>}</Title>
                            {/** Analysis result are already inside the currentExpandedAnalysisState, they have to be plotted now. All the other dashboards must be updated with this funct.*/}
                        
                            {currentExpandedAnalysisState?.risultato?.length > 0 &&
                                <div className="resultsContainer">
                                    {currentExpandedAnalysisState?.risultato?.map((item:any, index:number) => (
                                        <div key={index} className="resultItem">
                                            <strong style={{color:"var(--blue-de-france)", fontSize:'0.6rem'}}>{item.analita}</strong> 
                                            {item.valore}
                                        </div>
                                    ))}
                                </div>
                            }

                            {currentExpandedAnalysisState?.predizione &&
                                <div className="analysisDetailsContainer">
                                    

                                    <div className='predictionContainer'>

                                        <div className="containerRow"> 
                                            <span style={{fontSize:'12px'}}><strong>Predizione Esame</strong></span> 
                                        </div>

                                        <div className="containerRow"> 
                                            <div style={{padding:'10px'}}>
                                                <strong>
                                                    <span style={{ color: "var(--blue-de-france)", fontSize: currentExpandedAnalysisState?.predizione?.length > 50 ? '9px' : '12px', margin:'0 auto' }}>
                                                    {
                                                        currentExpandedAnalysisState?.predizione &&
                                                        currentExpandedAnalysisState.predizione.match(/^(.+?)\s+(\d+\.?\d*%)/)?.[1]
                                                    }
                                                    </span>
                                                </strong>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='confidenceContainer'>

                                        <div className="containerRow"> 
                                            <span style={{fontSize:'12px'}}><strong>Fattore di Confidenza</strong></span> 
                                        </div>

                                        <div className="containerRow"> 
                                            <div style={{padding:'10px'}}>
                                                <strong>
                                                    <span style={{ color: "var(--blue-de-france)", fontSize: '12px', margin:'0 auto' }}>
                                                    {
                                                        currentExpandedAnalysisState?.predizione &&
                                                        currentExpandedAnalysisState.predizione.match(/^(.+?)\s+(\d+\.?\d*%)/)?.[2]
                                                    }
                                                    </span>
                                                </strong>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                            }

                    </Card>

                    {/** EXPANDED VCF ANALYSIS AREA */}
                    <Card id={isExpandedVCFView ? "expandedAnalysisModalVCFVisible" : "expandedAnalysisModalVCFHidden"}  >
                        <div className='displayFlex' style={{justifyContent:'space-between'}}><Title  class="mb-0 marginBottomImportantModal ">Dettaglio Analisi {isExpandedVCFView && <span style={{color:"var(--blue-de-france)"}}>
                            {"VCF " + currentExpandedVCF.data_analisi}</span>}</Title> <Title  class="mb-0 marginBottomImportantModal "><div style={{cursor:'pointer'}} onClick={() => {setIsExpandedVCFView(false); setCurrentExpandedVCF(null)}}>X</div></Title></div>
                            {/** Analysis result are already inside the currentExpandedAnalysisState, they have to be plotted now. All the other dashboards must be updated with this funct.*/}
                        
                            {currentExpandedVCF &&
                                    <DataGrid
                                    // onRowClick={(params) => {}}
                                    sx={
                                            {
                                                height: 600,

                                                '& .MuiDataGrid-columnHeaders': {
                                                backgroundColor: '#f6f7fb'
                                                },
                                                '& .MuiDataGrid-cell--editing': {
                                                    outline: 'none'  // Remove outline from cell when editing
                                                },
                                                '& .MuiDataGrid-cell:focus': {
                                                    outline: 'none'  // Remove focus outline from cell
                                                },
                                                '& .MuiDataGrid-columnHeaderTitle': {
                                                    fontWeight: 'bold',
                                                    textAlign: 'center',
                                                    flex: 1
                                                },
                                                '& .MuiDataGrid-columnHeaderTitleContainer': {
                                                justifyContent: 'center', 
                                                },
                                                '& .MuiDataGrid-cell': {
                                                textAlign: 'center', 
                                                justifyContent: 'center',
                                                display: 'flex', 
                                                alignItems: 'center'
                                                }
                                            }
                                        }
                                        rowHeight={50}
                                        // initialState={{
                                        //     columns: {
                                        //     columnVisibilityModel: {
                                        //         roomNumber: false,
                                        //     },
                                        //     },
                                        // }}
                                        columns={[
                                            { field: 'ALT', headerName: 'ALT', flex: 1 },
                                            { field: 'CHROM', headerName: 'CHROM',  flex: 1}, 
                                            { field: 'POS', headerName: 'POS',  flex: 1},
                                            { field: 'QUAL', headerName: 'QUAL',  flex: 1},
                                            { field: 'REF', headerName: 'REF',  flex: 1},
                                            { field: 'prediction', headerName: 'Predizione',  flex: 1, renderCell: ({row}) => {
                                                return <strong className="customPredictionResultLabel" style={{ color:`${row.predizione && Number(row.predizione?.match(/^(.+?)\s+(\d+\.?\d*%)/)?.[2].replace("%", "")) >= 50 ? "" : "black"}` }}> {row.predizione ? Number(row.predizione?.match(/^(.+?)\s+(\d+\.?\d*%)/)?.[2].replace("%", "")) >= 50 ? "DISPONIBILE" : "DISPONIBILE (NDS)" : "NON DISPONIBILE"} </strong>
                                            }}, 
                                            { field: 'reason', headerName: 'Reason',  minWidth:250, renderCell: ({row}) => {
                                                return <strong className="customAnalysisResultLabel"> {row.reason} </strong>
                                            }}, 
                                            { field: 'score', headerName: 'Score',  flex: 1},


                                        ]}
                                        rows={currentExpandedVCF.vcf ? currentExpandedVCF.vcf.map((item:any, index:any) => ({ ...item, id: index })) : []}
                                        slots={{
                                            toolbar: CustomToolbar,
                                            // noRowsOverlay: getCustomNoRowsOverlay(!currentAnalysisList ? "" : currentAnalysisList.length === 0 ? "No Data" : "Caricamento.."),
                                        }}
                                        
                                    />
                            }

                    </Card>

                    
                        {/** ANALYSIS LIST AREA */}
                        <Card id="analysisList">
                        <Title  class="mb-0 marginBottomImportant "><span className="interactiveTitleDark" onClick={(e) => {handleExamClick(e)}}>{currentExamType}</span>  { currentAnalysisList && <span style={{color:"var(--blue-de-france"}}>{selectedPatientRef.current?.tax_code.toUpperCase()}</span>}</Title>
                        {isExamDropdownVisible && 
                                    <SimpleDropDownCheck
                                        options={[{code:0, label:"LISTA ESAMI LABORATORIO"},{code:1, label:"LISTA ESAMI GENETICI"}]}
                                        onSelect={(chart:any) =>{handleExamSelection(chart.label)}}
                                        position={startExamDropdownPosition}
                                        onClose={() => {seIsExamDropdownVisible(false)}}
                                    />}

                            {/** LAB ANALYSIS */}    
                            {currentExamType === "LISTA ESAMI LABORATORIO" && 
                                <DataGrid
                                onRowClick={(params) => {
                                                        fetchTrackingStatus(selectedPatientRef.current.tax_code, params.row.id_tag); 
                                                        setSelectedExamRoom(params.row.roomNumber);
                                                        setSelectedSubLab(params.row.lab);
                                                        params.row.esame !== "Emocromo" && setSelectedAnalysis(params.row);
                                                        isExpandedVCFView && setCurrentExpandedVCF(null); isExpandedVCFView && setIsExpandedVCFView(false);
                                                        //
                                                        setSelectedAnalysisId(params.row.id);
                                                        onAnalysisTypeClick(params.row.esame)
                                                    }}
                                sx={
                                        {
                                            height: 600,

                                            '& .MuiDataGrid-columnHeaders': {
                                            backgroundColor: '#f6f7fb'
                                            },
                                            '& .MuiDataGrid-cell--editing': {
                                                outline: 'none'  // Remove outline from cell when editing
                                            },
                                            '& .MuiDataGrid-cell:focus': {
                                                outline: 'none'  // Remove focus outline from cell
                                            },
                                            '& .MuiDataGrid-columnHeaderTitle': {
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                                flex: 1
                                            },
                                            '& .MuiDataGrid-columnHeaderTitleContainer': {
                                            justifyContent: 'center', 
                                            },
                                            '& .MuiDataGrid-cell': {
                                            textAlign: 'center', 
                                            justifyContent: 'center',
                                            display: 'flex', 
                                            alignItems: 'center'
                                            }
                                        }
                                    }
                                    rowHeight={30}
                                    initialState={{
                                        columns: {
                                        columnVisibilityModel: {
                                            roomNumber: false,
                                        },
                                        },
                                    }}
                                    columns={[
                                        {
                                            field: 'data_prelievo',
                                            headerName: 'Data Esame',
                                            minWidth: 150,
                                        },
                                        { field: 'id_tag', headerName: 'ID Campione', flex: 1 },
                                        { field: 'esame', headerName: 'Tipo Esame', flex: 1, renderCell: ({row}) => {
                                            return <strong className="customAnalysisResultLabel" style={{paddingRight:'5px'}}> {row.esame.toUpperCase()} </strong>
                                        }},
                                        { field: 'esito', headerName: 'Esito Esame',  flex: 1}, 
                                        { field: 'risultato', headerName: 'Risultato Esame',  flex: 1, renderCell: renderCellFunctionExpandedAnalysis}, 
                                        { field: 'valori_normali', headerName: 'Valori Normali',  flex: 1}, 
                                        { field: 'predizione', headerName: 'Predizione Esame', flex: 1, renderCell: ({row}) => {
                                            return <strong className="customPredictionResultLabel" style={{ color:`${row.predizione ? "" : "black"}` }}> {row.predizione ? "DISPONIBILE" : "NON DISPONIBILE"} </strong>
                                        }}, 
                                        { field: 'roomNumber', headerName: 'Stanza'}


                                    ]}
                                    rows={combinedAnalysisData ?? []}
                                    slots={{
                                        toolbar: CustomToolbar,
                                        noRowsOverlay: getCustomNoRowsOverlay(isAnalysisLoading ? "Caricamento.." : "Selezionare un Paziente con Esami di Laboratorio per visualizzare i dati"),
                                    }}
                                    // getRowClassName={(params) => {
                                    //     if(params.row.validation === null) return '';
                                    //     return params.row.validation ? 'valid-row' : 'invalid-row';
                                    // }} 
                                    
                                />

                            }

                            {/** VCF ANALYSIS */}    
                            {currentExamType === "LISTA ESAMI GENETICI" && 

                                <DataGrid
                                onRowClick={(params) => {
                                    fetchTrackingStatus(params.row.CF, params.row.id_campione); 
                                }}
                                sx={
                                        {
                                            height: 600,

                                            '& .MuiDataGrid-columnHeaders': {
                                            backgroundColor: '#f6f7fb'
                                            },
                                            '& .MuiDataGrid-cell--editing': {
                                                outline: 'none'  // Remove outline from cell when editing
                                            },
                                            '& .MuiDataGrid-cell:focus': {
                                                outline: 'none'  // Remove focus outline from cell
                                            },
                                            '& .MuiDataGrid-columnHeaderTitle': {
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                                flex: 1
                                            },
                                            '& .MuiDataGrid-columnHeaderTitleContainer': {
                                            justifyContent: 'center', 
                                            },
                                            '& .MuiDataGrid-cell': {
                                            textAlign: 'center', 
                                            justifyContent: 'center',
                                            display: 'flex', 
                                            alignItems: 'center'
                                            }
                                        }
                                    }
                                    rowHeight={30}
                                    initialState={{
                                        columns: {
                                        columnVisibilityModel: {
                                            roomNumber: false,
                                        },
                                        },
                                    }}
                                    columns={[
                                        {
                                            field: 'data_analisi',
                                            headerName: 'Data Esame',
                                            flex: 1,
                                        },
                                        { field: 'id_campione', headerName: 'ID Campione', flex: 1 },
                                        { field: 'vcf', headerName: 'VCF', flex: 1, renderCell: renderCellFunctionExpandedVCF },
                                        { field: '', headerName: 'SelcaGenetics', flex: 1, renderCell: ({row}) => {
                                            return <strong onClick={(e) => {e.stopPropagation(); window.open("https://genetics.selca.sicilia.it/analisi", "_blank")}} className="customAnalysisResultLabel" style={{paddingRight:'5px', cursor:'pointer'}}> LINK PIATTAFORMA </strong>
                                        }}


                                    ]}
                                    rows={currentVCF ?? []}
                                    slots={{
                                        toolbar: CustomToolbar,
                                        noRowsOverlay: getCustomNoRowsOverlay(isAnalysisLoading ? "Caricamento.." : "Selezionare un Paziente con Esami Genetici per visualizzare i dati"),
                                    }}
                                    
                                />     
                                                           
                            }   
                    

                    </Card>


                    <Card id="analysisHistory">
                        <Title  class="mb-0 marginBottomImportant "><span>{"GRAFICO ESAME"}</span> <span style={{color:'var(--blue-de-france)'}}>{selectedAnalysis?.esame}</span></Title>                          
                        
                        {currentChartType === "GRAFICO ESAME" && chartData.uom &&

                            <ResponsiveContainer width="100%" height="100%">

                                <LineChart margin={{top: 20}} data={chartData.chartData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="label" />
                                    <YAxis domain={[0,  chartData.minMax[1] * 2]} unit={chartData.uom} />
                                    <Tooltip />
                                    <Line type="monotone" dataKey="value" stroke="#8884d8" dot={{ r: 5 }} activeDot={{ r: 8 }} />
                                    <Brush dataKey="label" height={30} stroke="var(--blue-de-france)" />
                                    {chartData.minMax[0] !== null && <ReferenceLine y={chartData.minMax[0]} stroke="red" label={{value: `Min: ${chartData.minMax[0]}`, position:'insideTop'}} />}
                                    {chartData.minMax[1] !== null && <ReferenceLine y={chartData.minMax[1]} stroke="red" label={{value: `Max: ${chartData.minMax[1]}`, position:'insideTop'}} />}
                                </LineChart>

                            </ResponsiveContainer>

                        }
                            

                        
                    </Card>


                    {/* <Card id="predictionsList">
                    <Title  class="mb-0 marginBottomImportant ">Lista Predizioni</Title>
                        <DataGrid
                        // onRowClick={(params) => {fetchAnalysisList(params.row.tax_code)}}
                        sx={
                                {
                                    height: 530,

                                    '& .MuiDataGrid-columnHeaders': {
                                    backgroundColor: '#f6f7fb'
                                    },
                                    '& .MuiDataGrid-cell--editing': {
                                        outline: 'none'  // Remove outline from cell when editing
                                    },
                                    '& .MuiDataGrid-cell:focus': {
                                        outline: 'none'  // Remove focus outline from cell
                                    },
                                    '& .MuiDataGrid-columnHeaderTitle': {
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        flex: 1
                                    },
                                    '& .MuiDataGrid-columnHeaderTitleContainer': {
                                    justifyContent: 'center', 
                                    },
                                    '& .MuiDataGrid-cell': {
                                      textAlign: 'center', 
                                      justifyContent: 'center',
                                      display: 'flex', 
                                      alignItems: 'center', 
                                    }
                                }
                            }
                            rowHeight={30}
                            columns={[
                                // { field: 'id_patient', headerName: 'ID Paziente', width: 80}, 
                                {
                                    field: 'data_prelievo',
                                    headerName: 'Data Esame',
                                    minWidth: 150,
                                },
                                { field: 'esame', headerName: 'Tipo Esame', minWidth: 150},
                                { field: 'predizione', headerName: 'Predizione', flex: 1, renderCell: ({row}) => {
                                    return <strong className="customPredictionResultLabel"> {row.predizione} </strong>
                                }}
                            ]}
                            rows={filteredEmocromoList ?? []}
                            slots={{
                                toolbar: CustomToolbar,
                                noRowsOverlay: getCustomNoRowsOverlay("Selezionare un Esame per visualizzare le Predizioni"),
                            }}
  
                            
                        />
                        
                    </Card> */}


                </main>
            </div>
        </Fade>
    );
};
export default React.memo(DoctorDashboard);


// http://localhost:3001/