import './css/Site.css';
import React, { useMemo, useEffect, useContext } from 'react';

import Navbar from '../components/Navbar';
// import Sidebar from '../components/Sidebar';

import DoctorDashboard from './DoctorDashboard';

import { BrowserRouter as Router, Route, Routes, Navigate  } from 'react-router-dom'

import { Fade } from '@mui/material';

import CustomSnackbar from '../components/CustomSnackbar'
import { ctxSession, ctxSnackbar } from '../store';

import Axios from '../helpers/axios';

import { SessionModel } from '../models';
import { UserData_model } from '../models/data/UserData_model';

import { decodeToken } from 'react-jwt';
import LabDashboard from './LabDashboard';
import PatientDashboard from './PatientDashboard';
import ReporterDashboard from './ReporterDashboard';
import MaintenanceDashboard from './MaintenanceDashboard';
import ControlDashboard from './ControlDashboard';

const Site = () => {

    const session = useContext(ctxSession);
    const snackbar = useContext(ctxSnackbar);

    useEffect( () => {
        if(session && session.data && session.data.token && !session.data?.userData ){

            const onSuccess = (response:any) => {
                if(session && session.data && session.data.token && session.set){
                    const decodedToken : UserData_model | null = decodeToken(session.data.token);
        
                    const sess : SessionModel = {
                        token: session.data.token,
                        userData: decodedToken,
                    }
                    session.set(sess)
                }
            } 
            Axios(session, 'get', `/api/auth/`, onSuccess);


        }
    }, [] );

    const page = useMemo( () => {
        if(session && session.data && session.data.userData){
            
            if( session.data.userData.roles.includes('patient') ){

                return <PatientDashboard />;
            }else if( session.data.userData.roles.includes('doctor') ){
                console.log(session.data.userData)

                return <DoctorDashboard />;
            }else if( session.data.userData.roles.includes('maintenance')){

                return <MaintenanceDashboard />;
            }else if( session.data.userData.roles.includes('laboratory')){

                return <LabDashboard />;
            }else if( session.data.userData.roles.includes('reporter')){

                return <ReporterDashboard />;
            }
            else if( session.data.userData.roles.includes('control')){

                return <ControlDashboard />
            }else{
                return <></>
            }
            return <></>
        
        }


    }, [session, session?.data]);

    return (
        <Fade in={true}>
            <div id="Site">
                {/* <Sidebar /> */}
                <div id="content">
                    <Navbar />
                    <div className="pages">
                        <Routes>
                            <Route path="/" element={page} /> 
                            { /*
                            <Route path="/employees" element={<Employees />} />
                            <Route path="/employee" element={<Employee />} />

                            <Route path="/test" element={<Test />} /> */}
                            {/* <Route path="/ForgotPassword" element={<ForgotPassword />} /> */}
                            {/* <Route
                                path="*"
                                element={<Navigate to="/" />}
                            /> */}
                        </Routes>
                    </div>
                </div>

                <div id="snackbarContainer">
                { snackbar && snackbar.data &&
                    snackbar.data.map((snack, i) => {
                        return (
                            <CustomSnackbar key={i} {...snack} />
                        )
                    })
                
                }
                </div>
            </div>
            

        </Fade>
    );
};
export default React.memo(Site);


// gestcore 4
// c#
// riscrivere
