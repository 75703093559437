import axios from 'axios';
import './css/LabDashboard.css';
import React, { useState, useEffect, useContext, useRef } from 'react';

import { ctxSession } from '../store';

import Card from '../components/Card';
import Title from '../components/Title';
import CustomInput from '../components/CustomInput';
import IconButton from '@mui/material/IconButton';

import PersonAddIcon from '@mui/icons-material/PersonAdd';

import Fade from '@mui/material/Fade';

import Axios from '../helpers/axios';

import Divider from '@mui/material/Divider';

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarQuickFilter,
  GridRowSelectionModel
} from '@mui/x-data-grid';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';


import { taxCodeHTMLRegex } from '../helpers/validator';
import formDataValidator from '../helpers/formData';
import { AxiosError, AxiosResponse } from 'axios';

import CustomTracker from '../components/CustomTracker'
import SimpleDropDownCheck from '../components/SimpleDropDownCheck';
import { Brush, CartesianGrid, Legend, LegendProps, Line, LineChart, Rectangle, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import moment from 'moment';
import LabsMap from '../../assets/images/labs.jpg'

const today = new Date();
const todayIsoString = today.toISOString();


const MaintenanceDashboard = () => {

    const session = useContext(ctxSession);

    // Lab registers
    const [startLabDropdownPosition, setStartLabDropdownPosition] = useState<{ top: number, left: number } | null>(null);
    const [isLabDropdownVisible, seIstLabDropdownVisible] = useState<boolean>(false);
    const [startChartDropdownPosition, setStartChartDropdownPosition] = useState<{ top: number, left: number } | null>(null);
    const [isChartDropdownVisible, seIsChartDropdownVisible] = useState<boolean>(false);
    const [startExamDropdownPosition, setStartExamDropdownPosition] = useState<{ top: number, left: number } | null>(null);
    const [isExamDropdownVisible, seIsExamDropdownVisible] = useState<boolean>(false);

    // Patients register
    const [patients, setPatients] = useState<any>([]);
    const selectedPatientRef = useRef<any>(null);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);

    // Analysis register
    const [currentAnalysisList, setCurrentAnalysisList]  = useState<any>([]);

    // Emocromo register
    const [currentEmocromoList, setCurrentEmocromoList]  = useState<any>([]);

    // VCF Rrister
    const [currentVCF, setCurrentVCF] = useState<any>([]);

    // Tracking register
    const [currentTrackingStatus, setCurrentTrackingStatus]  = useState<any>([]);

    // Combined data register
    const [combinedAnalysisData, setCombinedAnalysisData] = useState<any>([]);

    // Room register
    const [selectedExamRoom, setSelectedExamRoom] = useState<any>(null);
    const [selectedSubLab, setSelectedSubLab] = useState<any>([]);

    // InstrumentData register
    const [currentInstrumentData, setCurrentInstrumentData] = useState<any>([])
    const [selectedInstrumentType, setSelectedInstrumentType] = useState<any>(null);
    const [selectedInstrumentUOM, setSelectedInstrumentUOM] = useState<any>([]);

    // ExpandedAnalysisView register
    const [isExpandedAnalysisView, setIsExpandedAnalysisView] = useState<any>(null);
    const [currentExpandedAnalysisState, setCurrentExpandedAnalysisState] = useState<any>(null);
    const renderCellFunctionExpandedAnalysis = (params:any) => {
        const isObject = typeof params.row.risultato === 'object' && params.row.risultato !== null;
        const displayValue = isObject 
            ? <strong 
                className="customExpandResult" 
                onMouseOver={() => {setCurrentExpandedAnalysisState(params.row); setIsExpandedAnalysisView(true)}}
                onMouseLeave={() => setIsExpandedAnalysisView(false)}
              >
                ESPANDI RISULTATO
              </strong> 
            : <span className="customResultCell">{params.row.risultato + " " + params.row.unita_misura}</span>;
    
        return <>{displayValue}</>;
    };

    // SelectedAnalysisRegister
    const [selectedAnalysis, setSelectedAnalysis] = useState<any>(null);

    // CHart type selector
    const [currentLabName, setCurrentLabName] = useState<any>(session?.data?.userData.name);
    const [currentChartType, setCurrentChartType]  = useState<any>("VALORI AMBIENTALI");
    const [currentExamType, setCurrentExamType]  = useState<any>("LISTA ESAMI LABORATORIO");

    // Chart register
    // Sample data
    const data = [
        { timestamp: '2022-01-01T12:00:00', value: 10 },
        { timestamp: '2022-01-02T12:00:00', value: 15 },
        { timestamp: '2022-01-03T12:00:00', value: 10 },
    ];
    const examTimestamp = '2022-01-02T12:00:00'; // Example exam timestamp
    const lineColors = ['#8884d8', '#d45e6c', '#ffc658', '#83a6ed', '#8dd1e1', '#a4de6c', '#d0ed57', '#fa8c8c', '#7692ff', '#ffcb69'];
    const chartWidth = 1500;
    const [chartKey, setChartKey] = useState(0);
    const handleDoubleClick = () => {
        setChartKey(prevKey => prevKey + 1);
    };

    // TimeSeries register
    const [currentTimeSeries, setCurrentTimeSeries] = useState<any>(null);
    const [currentTransformedTimeSeries, setCurrentTransformedTimeSeries] = useState<any>(null);
    const [currentClippedTimeSeries, setCurrentClippedTimeSeries] = useState<any>(null);

    // Alarms register
    const [currentInstrumentAlarms, setCurrentInstrumentAlarms] = useState<any>([])
    const [selectedMarker, setSelectedMarker] = useState<any>(null);
    const [markerDropDownPosition, setMarkerDropdownPosition] = useState<any>({ top: 0, left: 0 });

    // VCF register
    const [currentExpandedVCF, setCurrentExpandedVCF] = useState<any>(null);
    const [isExpandedVCFView, setIsExpandedVCFView] = useState<any>(null);
    const renderCellFunctionExpandedVCF = (params:any) => {
        const isObject = typeof params.row.vcf === 'object' && params.row.vcf !== null;
        const displayValue = isObject 
            ? <strong 
                className="customExpandResult" 
                onClick={() => {!isExpandedVCFView ? setCurrentExpandedVCF(params.row) : setCurrentExpandedVCF(null); setIsExpandedVCFView(!isExpandedVCFView)}}
              >
                ESPANDI RISULTATO
              </strong> 
            : <span className="customResultCell">NESSUN RISULTATO</span>;
    
        return <>{displayValue}</>;
    };
    
    enum AlarmCode {
        GREEN = 0,
        YELLOW = 1,
        ORANGE = 2,
        RED = 3
    }

    // Alert rendering map
    const AlertMapIndicator: { [key in AlarmCode]: string } = {
        [AlarmCode.GREEN]: "sensor-marker-clear",
        [AlarmCode.YELLOW]: "sensor-marker-yellow",
        [AlarmCode.ORANGE]: "sensor-marker-orange",
        [AlarmCode.RED]: "sensor-marker-alert"
    };

    // Chart room register
    const [selectedRoom, setSelectedRoom] = useState('10'); // default to room '10'
    
    // DateRange picker register
    const minDate = '2023-12-12';
    const [dateRange, setDateRange] = useState({
        startDate: minDate,
        endDate: new Date().toISOString().split('T')[0] // Default to today's date
    });

    /**
    // DateRange picker register
    const minDate = new Date();
    minDate.setDate(minDate.getDate() - 1); // Set to yesterday

    const [dateRange, setDateRange] = useState({
        startDate: minDate.toISOString().split('T')[0], // Yesterday's date
        endDate: new Date().toISOString().split('T')[0] // Default to today's date
    });
    */

    // Markers handling
    const imageRef = useRef<any>(null);
    const markers = [
        { id: "air_housekeeper_1.10", top: '4.75%', right: '80.45%' },
        { id: "air_housekeeper_1.11", top: '4.75%', right: '64.5%' },
        { id: "air_housekeeper_1.12", top: '65.5%', right: '80%' },
        { id: "air_housekeeper_1.13", top: '65.6%', right: '64.25%' },
        { id: "air_housekeeper_2.10", top: '17%', right: '59.05%' },
        { id: "air_housekeeper_2.11", top: '2.69%', right: '43.55%' },
        { id: "air_housekeeper_2.12", top: '17%', right: '28.4%' },
        { id: "air_housekeeper_3.10", top: '44.65%', right: '46.6%' },
        { id: "air_housekeeper_3.11", top: '43%', right: '28%' },
        { id: "air_housekeeper_3.12", top: '68.6%', right: '28.35%' },
    ];

    const updateMarkerPositions = () => {
        if (imageRef.current) {
            const rect = imageRef.current.getBoundingClientRect();
    
            markers.forEach(marker => {
                const markerElement = document.getElementById(marker.id);
                if (markerElement) {
                    const topPercent = parseFloat(marker.top.replace('%', ''));
                    const rightPercent = parseFloat(marker.right.replace('%', ''));
                    const top = topPercent / 100 * rect.height;
                    const right = rightPercent / 100 * rect.width;
                    markerElement.style.top = `${top}px`;
                    markerElement.style.left = `${rect.width - right}px`;
                }
            });
        }
    };
    

    useEffect(() => {
        const handleResize = () => {
            updateMarkerPositions();
        };
    
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array to run only once on mount













    // Function to format timestamp (UTC)
    const formatTimestamp = (timestamp:any) => {
        const date = new Date(timestamp);
        const year = date.getUTCFullYear();
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const day = date.getUTCDate().toString().padStart(2, '0');
        const hour = date.getUTCHours().toString().padStart(2, '0');
        const minute = date.getUTCMinutes().toString().padStart(2, '0');
    
        return `${year}-${month}-${day} ${hour}:${minute}`;
    }

    /** System utilities */

    // function shiftAndRebaseTimeseries(timeseries:any, targetDate:any, endDate:any = null, pointsBeforeAfter = 80) {
    //     if (!timeseries || timeseries.length === 0) {
    //         console.error("Timeseries data is null, undefined, or empty.");
    //         return [];
    //     }
    
    //     // Convert target date to timestamp
    //     const targetTimestamp = new Date(targetDate).getTime();
    
    //     // Sort the timeseries
    //     const sortedTimeseries = timeseries.sort((a:any, b:any) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
    
    //     // Find the index of the closest timestamp to the target date
    //     let closestIndex = sortedTimeseries.findIndex((item:any) => new Date(item.timestamp).getTime() >= targetTimestamp);
    //     if (closestIndex === -1 || new Date(sortedTimeseries[closestIndex].timestamp).getTime() > targetTimestamp) {
    //         closestIndex = Math.max(closestIndex - 1, 0);
    //     }
    
    //     // Calculate the time difference
    //     const closestTimestamp = new Date(sortedTimeseries[closestIndex].timestamp).getTime();
    //     const timeDiff = targetTimestamp - closestTimestamp;
    
    //     // Shift all timestamps
    //     const shiftedTimeseries = sortedTimeseries.map((item:any) => {
    //         const newTimestamp = new Date(new Date(item.timestamp).getTime() + timeDiff).toISOString();
    //         return { ...item, timestamp: newTimestamp };
    //     });
    
    //     // Calculate start and end indices to clip the timeseries
    //     const startIndex = Math.max(closestIndex - pointsBeforeAfter, 0);
    //     const endIndex = Math.min(closestIndex + pointsBeforeAfter, shiftedTimeseries.length - 1);
    
    //     return shiftedTimeseries.slice(startIndex, endIndex + 1);
    // }
    // useEffect(() => {
    //     const newData = shiftAndRebaseTimeseries(currentTransformedTimeSeries, todayIsoString);
    //     setCurrentClippedTimeSeries(newData);
    // }, [currentTransformedTimeSeries]);

    // Reset Global States
    function resetAllStates(){
        setCurrentAnalysisList(null);
        setCurrentEmocromoList(null);
        setCurrentTrackingStatus(null);
        setCombinedAnalysisData(null);
        setSelectedExamRoom(null);
        setSelectedSubLab(null);
        selectedPatientRef.current = null;
        setSelectionModel([]);
        setCurrentInstrumentData(null);
        setSelectedInstrumentType(null);
        setSelectedInstrumentUOM(null);
        setIsExpandedAnalysisView(null);
        setSelectedAnalysis(null);
        setCurrentTimeSeries(null);
        setCurrentTransformedTimeSeries(null);
        setCurrentClippedTimeSeries(null);
        setCurrentVCF(null);
    }

    // Reset InstrumentModel state whenever the currentAnalysisList changes
    useEffect(() => {

        setSelectedInstrumentType(null);
        setSelectedInstrumentUOM(null);

    }, [currentAnalysisList, selectedExamRoom])

    // Reset TrackingStatus whenever the InstrumentData changes
    useEffect(() => {

        setCurrentTrackingStatus(null);

    }, [currentInstrumentData])



    /** @section Fetching */

    // Show UserAccount
    useEffect(() => {

        console.log("Account type:", session?.data?.userData?.accountType)
        console.log("Account role:", session?.data?.userData?.roles[0])
        
    }, [session?.data?.userData?.accountType])

    /** Fetch InstrumentData for the SelectedAnalysis */
    const fetchInstrumentData = (roomID:string) => {

        const onSuccess = (response:AxiosResponse) => {

            if( response.data ){
                
                /** @warning Type and Format check is required here when the shape is final */

                setCurrentInstrumentData(response.data);
                console.log("InstrumentData fetched: ", response.data);
                
            }
        }

        const onError = (error:AxiosError) => {
            console.error("Error getting InstrumentData: ", error.message)
        }

        // Reset instrument status
        setCurrentInstrumentData(null);

        Axios(session, 'get', `/api/environment/GetInstrumentsQueryParamsMtz/${session?.data?.userData?.code}/${roomID}`, onSuccess, onError);

    }

    /** Fetch InstrumentTimeSeries for the SelectedAnalysis */
    const fetchInstrumentTimeSeries = async () => {
        if (!currentInstrumentData || !Array.isArray(currentInstrumentData.data) || currentInstrumentData.data.length === 0) {
            console.log('No valid instrument data available');
            return;
        }

        let accumulatedData: any = {};

        // Ensure start date is not earlier than minDate
        let validStartDate = new Date(dateRange.startDate) < new Date(minDate) ? minDate : dateRange.startDate;


        const requests = currentInstrumentData.data
            .filter((instrument: any) => instrument.roomNumber === selectedRoom)
            .map((instrument: any) => {
                const url = `/api/environment/GetInstrumentsTimeSeriesLab/${session?.data?.userData?.code}/${instrument.fullName}/${encodeURIComponent(instrument.uom[0])}/${validStartDate}/${dateRange.endDate}`;
                return axios.get(url, {headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}})
                    .then((response: any) => {
                        if (response?.data) {
                            response.data.data.forEach((dataPoint: any) => {
                                if(instrument.type === "carbon_dioxide") return; // TODO: Create a selector
                                const timestamp = new Date(dataPoint[0]).toISOString();
                                if (!accumulatedData[timestamp]) {
                                    accumulatedData[timestamp] = { timestamp };
                                }
                                accumulatedData[timestamp][instrument.type] = dataPoint[1];
                            });
                        }
                    })
                    .catch((error: any) => {
                        console.error("Error getting TimeSeries: ", error.message);
                    });
            });

        // Wait for all requests to complete
        await Promise.all(requests);

        // Set the accumulated data
        setCurrentTransformedTimeSeries(Object.values(accumulatedData));
    };
    useEffect(() => {
        if (currentInstrumentData && Array.isArray(currentInstrumentData.data) && currentInstrumentData.data.length > 0) {
            fetchInstrumentTimeSeries();
        }
    }, [selectedRoom, dateRange.startDate, dateRange.endDate, currentInstrumentData]);

    /** Fetch InstrumentTimeSeries for the SelectedAnalysis */
    const fetchInstrumentAlarms = async () => {
        if (!currentInstrumentData || !Array.isArray(currentInstrumentData.data) || currentInstrumentData.data.length === 0) {
            console.log('No valid instrument data available');
            return;
        }
    
        let allInstrumentAlarms = [];
        let requestedCombinations = new Set();
    
        for (const instrument of currentInstrumentData.data) {
            const instrumentId = `${instrument.model}_${instrument.labID}.${instrument.roomNumber}`;
    
            // Check if this combination has already been requested
            if (requestedCombinations.has(instrumentId)) {
                continue; // Skip this iteration if already requested
            }
    
            requestedCombinations.add(instrumentId); // Add to the Set to track requested combinations
    
            const url = `/api/environment/GetAlertsFromInstrumentId/${instrumentId}`;
            try {
                const response = await axios.get(url, {headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}});
                if (response?.data) {
                    allInstrumentAlarms.push({
                        instrumentId: instrumentId,
                        alarms: response.data
                    });
                    console.log(`Alarms downloaded for ${instrument.model} ${instrument.labID}.${instrument.roomNumber}`, response.data);
                }
            } catch (error:any) {
                console.error(`Error getting InstrumentAlarms for ${instrument.model}: `, error.message);
            }
        }
    
        setCurrentInstrumentAlarms(allInstrumentAlarms);
    };
    useEffect(() => {
        if (currentInstrumentData && Array.isArray(currentInstrumentData.data) && currentInstrumentData.data.length > 0) {
            // Call the function immediately on component mount
            fetchInstrumentAlarms();
    
            // Set up an interval to call the function every 6 seconds
            const intervalId = setInterval(fetchInstrumentAlarms, 6000);
    
            // Clean up the interval on component unmount
            return () => clearInterval(intervalId);
        }
    }, [currentInstrumentData]);

    
    

    /** @section Combining */

    /** Combine AnalysisList and EmocromoList */
    const combineAnalysisAndPredictionData = (analysisList:any, EmocromoList:any) => {

        // Early return if EmocromoList or VCFList is empty
        if (!EmocromoList || EmocromoList.length === 0) {
            return analysisList?.map((item:any, index:number) => ({
                    ...item,
                    id: index,
                }));
        }

        // Merge the two arrays
        const mergedArray = [...analysisList, ...EmocromoList];

        // Create a set to track unique id_tags
        const uniqueTags = new Set();

        // Filter out duplicates based on id_tag and assign unique ids
        const filteredMergedArray = mergedArray.filter((item: any, index: number) => {
            if (item.id_tag && uniqueTags.has(item.id_tag)) {
                return false; // Exclude duplicate
            }
            uniqueTags.add(item.id_tag);
            item.id = index; // Assigning a unique id
            return true;  // Include unique item
        });

        return filteredMergedArray;

    };
    useEffect(() => {

        // Combine AnalysisList and EmocromoList
        const combinedData = combineAnalysisAndPredictionData(currentAnalysisList, currentEmocromoList);
        setCombinedAnalysisData(combinedData); // Update state with combined data

    }, [currentEmocromoList])


    /** Debug */
    useEffect(() => {

        console.log("currentTransformedTimeSeries status changed")
        console.log(currentTransformedTimeSeries)

    }, [currentTransformedTimeSeries])


    /** @section Boot */

    useEffect(() => {

        fetchInstrumentData("");

    }, [])

    /** @section Handlers */

    const handleMarkerClick = (marker:any, clickEvent:React.MouseEvent) => {
        const icon = clickEvent.target as HTMLElement;
        const boundingRect = icon.getBoundingClientRect();
        const markerPosition = {
            top: boundingRect.bottom,
            left: boundingRect.left -1
        };
        setSelectedMarker(marker);
        setMarkerDropdownPosition(markerPosition);
    };
    

    const handleLabClick = (clickEvent:React.MouseEvent) => {

        // Set the GroupFilterDropdown position
        const icon = clickEvent.target as HTMLElement;
        const boundingRect = icon.getBoundingClientRect();
        setStartLabDropdownPosition({
            top: boundingRect.bottom,
            left: boundingRect.left -1
        });

        // Show Dropdown
        seIstLabDropdownVisible(true);

    }

    const handleLabSelection = (lab:any) => {

        // Set current SubLab
        if (session?.data?.userData){
            session.data.userData.code = lab.code;
            session.data.userData.name = lab.label;
            setCurrentLabName(session.data.userData.name);
        } 

        // Hide Dropdown
        seIstLabDropdownVisible(false);

    }

    const handleChartClick = (clickEvent:React.MouseEvent) => {

        // Set the GroupFilterDropdown position
        const icon = clickEvent.target as HTMLElement;
        const boundingRect = icon.getBoundingClientRect();
        setStartChartDropdownPosition({
            top: boundingRect.bottom,
            left: boundingRect.left -1
        });

        // Show Dropdown
        seIsChartDropdownVisible(true);

    }

    const handleChartSelection = (chart:any) => {

        // Set current SubLab
        if (session?.data?.userData){

            setCurrentChartType(chart);
        } 

        // Hide Dropdown
        seIsChartDropdownVisible(false);

    }

    const handleExamClick = (clickEvent:React.MouseEvent) => {

        // Set the GroupFilterDropdown position
        const icon = clickEvent.target as HTMLElement;
        const boundingRect = icon.getBoundingClientRect();
        setStartExamDropdownPosition({
            top: boundingRect.bottom,
            left: boundingRect.left -1
        });

        // Show Dropdown
        seIsExamDropdownVisible(true);

    }

    const handleExamSelection = (chart:any) => {

        // Set current SubLab
        if (session?.data?.userData){

            setCurrentExamType(chart);
        } 

        // Hide Dropdown
        seIsExamDropdownVisible(false);

    }

    

    /** @section Improper Components Section */

    /** TODO: Place these components properly */
    function CustomToolbar() {
        return (
        <GridToolbarContainer style={{ justifyContent: 'flex-end' }}>
            <GridToolbarFilterButton />
            <GridToolbarQuickFilter debounceMs={0}/>
        </GridToolbarContainer>
        );
    }

    function CustomNoRowsOverlay(props:any) {
        return (
          <div style={{ height: '100%', padding: 10, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {props.customText}
          </div>
        );
      }

    function getCustomNoRowsOverlay(message:string) {
        return function() {
            return <CustomNoRowsOverlay customText={message} />;
        };
    }

    // Custom Tooltip Component
    type InstrumentType = 'formaldehyde' | 'humidity' | 'particulate_matter_2_5_um' | 'temperature' | 'volatile_organic_compound';
    const typeColorMap: { [key in InstrumentType]: string } = {
        'formaldehyde': '#8884d8',
        'humidity': '#d45e6c',
        'particulate_matter_2_5_um': '#ffc658',
        'temperature': '#83a6ed',
        'volatile_organic_compound': '#8dd1e1'
    };
    const CustomTooltip = ({ active, payload, label, coordinate }: any) => {
        // Determine if tooltip should be mirrored based on cursor position
        const isMirrored = coordinate && coordinate.x > (chartWidth / 2);
        
        if (active && payload && payload.length) {
            const tooltipClass = isMirrored ? "custom-tooltip-mirrored-mtz" : "custom-tooltip-mtz";
            
            return (
                <div className={tooltipClass}>
                    <p><strong>{label}</strong></p>
                    <div className="custom-tooltip-content">
                    {payload.map((entry: any, index: any) => {
                        // Ensure entry.name is a valid instrument type
                        const instrumentType = entry.name as InstrumentType;
                        const color = typeColorMap[instrumentType] || '#8884d8'; // Default color
                            return (
                                <div key={index} style={{ color: color, padding: '2px', fontWeight: "bold" }}>
                                    {entry.value} {currentInstrumentData.data.find((instrument: any) => instrument.type === entry.name).uom}
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }
    
        return null;
    };

    const CustomLegendRenderer = ({ payload, lineColors }: any) => {
        if (!payload || !lineColors) {
            console.log("CustomLegendRenderer: payload or lineColors not provided");
            return null;
        }
    
        return (
            <ul className="custom-legend">
                {payload.map((entry: any, index: any) => (
                    <li key={`item-${index}`} className="custom-legend-item">
                        {/* SVG circle instead of a square */}
                        <svg height="10" width="10" className="custom-legend-icon">
                            <circle cx="5" cy="5" r="4" fill={lineColors[index % lineColors.length]} />
                        </svg>
                        <span className="custom-legend-text" style={{ color: lineColors[index % lineColors.length] }}>
                            {entry.value}
                        </span>
                    </li>
                ))}
            </ul>
        );
    };    
  

    const invitePatient = (e:React.FormEvent<EventTarget>) => {
        e.preventDefault();

        
        const formData = formDataValidator(e.target);
        console.log(formData)
    }


    return (
        <Fade in={true}>
            <div id="mtz">
                <main>
                  
                    {/** ANALYSIS HISTORY AREA */}
                    <Card id="analysisHistory">
                        <Title  class="mb-0 marginBottomImportant "><span className="interactiveTitleDark" onClick={(e) => {handleChartClick(e)}}>{currentChartType}</span> <span style={{color:"var(--blue-de-france"}}>LAB. {session?.data?.userData?.code} </span></Title>
                        {isChartDropdownVisible && 
                                <SimpleDropDownCheck
                                    options={[{code:0, label:"VALORI AMBIENTALI"},{code:1, label:"MAPPA ALLARMI"},{code:2, label:"STATO SENSORI"}]}
                                    onSelect={(chart:any) =>{handleChartSelection(chart.label)}}
                                    position={startChartDropdownPosition}
                                    onClose={() => {seIsChartDropdownVisible(false)}}
                                />}

                        {currentChartType === "VALORI AMBIENTALI" &&
                            <div className="displayFlexColumn" style={{
                                opacity: currentInstrumentData ? 1 : 0,
                                visibility: currentInstrumentData ? 'visible' : 'hidden',
                                transition: 'opacity 0.3s, visibility 0.3s',
                                padding: '20px'
                            }}> 

                                <div style={{marginBottom: '20px'}} className='displayFlex'>
                                    <label>DAL: </label>
                                    <input 
                                        type="date" 
                                        min={minDate}
                                        value={dateRange.startDate} 
                                        onChange={(e) => setDateRange({ ...dateRange, startDate: e.target.value })}
                                        style={{marginLeft:'10px'}}
                                    />
                                    <label style={{marginLeft:'20px'}}>AL: </label>
                                    <input 
                                        type="date" 
                                        min={dateRange.startDate}
                                        value={dateRange.endDate} 
                                        onChange={(e) => setDateRange({ ...dateRange, endDate: e.target.value })}
                                        style={{marginLeft:'10px'}}
                                    />
                                </div>

                                <select value={selectedRoom} onChange={(e) => setSelectedRoom(e.target.value)}>
                                    <option value="10">Stanza 10</option>
                                    <option value="11">Stanza 11</option>
                                    <option value="12">Stanza 12</option>
                                    <option value="13">Stanza 13</option>
                                </select>

                            </div>
                        }
                        
                        {/** ENVIRONMENTAL CHART */}
                        {currentTransformedTimeSeries && currentChartType === "VALORI AMBIENTALI" && currentInstrumentData &&
                            <div onDoubleClick={handleDoubleClick} className="chartContainer" style={{height:'640px'}}>  
                                
                                <ResponsiveContainer width="100%" height="100%">

                                    <LineChart
                                        key={chartKey}
                                        width={chartWidth}
                                        data={currentTransformedTimeSeries}
                                        margin={{ top: 20, right: 70, left: 10, bottom: 5 }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="timestamp" tickFormatter={formatTimestamp} />
                                        <YAxis orientation="left"/>
                                        <Tooltip content={<CustomTooltip lineColors={lineColors} />} />
                                        <Legend content={<CustomLegendRenderer lineColors={lineColors} />} />

                                        {/* Dynamically create Line for each instrument model */}
                                        {Object.keys(currentTransformedTimeSeries[0] || {})
                                            .filter(key => key !== 'timestamp')
                                            .sort()
                                            .map((measurementType, index) => (
                                                <Line
                                                    dot={false}
                                                    key={measurementType}
                                                    type="monotone"
                                                    dataKey={measurementType}
                                                    stroke={lineColors[index % lineColors.length]}
                                                />
                                            ))
                                        }

                                        <Brush dataKey="timestamp" height={30} stroke="var(--blue-de-france)" />
                                        {/* <ReferenceLine 
                                            x={currentClippedTimeSeries[40].timestamp} 
                                            stroke="var(--blue-de-france)" 
                                            label={{ value: todayIsoString, position: 'insideRight', fill: 'var(--blue-de-france)' }}
                                        /> */}
                                        
                                    </LineChart>

                                </ResponsiveContainer>

                            </div>
                        }
                        
                        {/** ALERT MAP  */}
                        {currentChartType === "MAPPA ALLARMI" && currentInstrumentData?.data && currentInstrumentAlarms && 
                            <div className='imageContainer'>
                                <div
                                    className={currentInstrumentAlarms ? "imageVisible" : "imageHidden"} 
                                    style={{ 
                                        position: 'relative',
                                        // backgroundImage: `url(${LabsMap})`,
                                        height: '100%',  
                                        width: '100%',   
                                        backgroundSize: '1300px',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center', 
                                        marginLeft:'35px',
                                        transition:'0.3s opacity',
                                        opacity: currentInstrumentAlarms ? 1 : 0,
                                        visibility: currentInstrumentAlarms ? 'visible' : 'hidden'
                                    }}>
                                </div>
                                <img 
                                    ref={imageRef}
                                    src={LabsMap} 
                                    onLoad={updateMarkerPositions} 
                                    alt="Map"
                                    style={{ 
                                        width: '100%', 
                                        height: '100%', 
                                        objectFit: 'contain', 
                                        position: 'absolute', 
                                        top: -100,
                                        left: 0,
                                        backgroundRepeat: 'no-repeat'
                                    }}
                                />
                                {markers.map(marker => {
                                    const instrumentId = marker.id;
                                    const alarmData = currentInstrumentAlarms.find((alarm:any) => alarm.instrumentId === instrumentId);
                                    const alarmSeverity = alarmData ? alarmData.alarms.color_code as keyof typeof AlertMapIndicator : AlarmCode.GREEN;
                                    const markerClass = AlertMapIndicator[alarmSeverity] || 'sensor-marker-clear';
                                    const isInstrumentPresent = currentInstrumentData.data.some((instr:any) => instr.fullName.includes(instrumentId));

                                    return (
                                        <div 
                                            key={marker.id}
                                            id={marker.id}
                                            className={isInstrumentPresent ? markerClass : 'sensor-marker-hidden'}
                                            style={{ 
                                                position: 'absolute',
                                                zIndex: '1113',
                                            }}    
                                            onClick={(e) => handleMarkerClick(marker, e)}
                                        ></div>
                                    );
                                })}

                                {selectedMarker && (
                                    <SimpleDropDownCheck
                                        options={selectedMarker.color_code === AlarmCode.GREEN ? 
                                            [{ code: 1, label: "ALZA Livello di Allarme" }] : 
                                            [{ code: 0, label: "ABBASSA Livello di Allarme" }]}
                                        position={markerDropDownPosition}
                                        onClose={() => setSelectedMarker(null)}
                                        onSelect={() => {}}
                                    />
                                )}

                                {/* Legend div */}
                                <div className="mapLegend">
                                    <ul>
                                        <li style={{color:'var(--green-2'}}>Operatività Normale</li>
                                        <li style={{color:'yellow'}}>Operatività in corso di Ispezione</li>
                                        <li style={{color:'orange'}}>Operatività in corso di Risoluzione</li>
                                        <li style={{color:'red'}}>Operatività Critica</li>
                                    </ul>
                                </div>

                            </div>
                        }

                        {/* LIVE SENSORS IFRAME */}
                        {currentChartType === "STATO SENSORI" &&
                            <div className='imageContainer'>
                                <iframe 
                                    src="http://49.12.212.22:24513/Selca"
                                    width="100%"
                                    height="100%"
                                    allowFullScreen
                                    title="Dashboard sensori ambientali Live"
                                ></iframe>
                        </div>
                        }


                            

                        
                    </Card>

                </main>
            </div>
        </Fade>
    );
};
export default React.memo(MaintenanceDashboard);


// http://localhost:3001/