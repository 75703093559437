import './css/Site.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as  Route, useNavigate  } from 'react-router-dom'


import Card from '../components/Card';
import CustomButton from '../components/CustomButton';
import CustomInput from '../components/CustomInput';

import importer from '../helpers/importer'
import Axios from '../helpers/axios';





const ForgotPassword = () => {

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [otc, setOtc] = useState('');
    const [otcSent, setOtcSent] = useState(false);

    // useEffect(() => {
    //     console.log(email)
    // },[email])

    // useEffect(() => {
    //     console.log(newPassword)
    // },[newPassword])

    // useEffect(() => {
    //     console.log(otc)
    // },[otc])

    const handleForgotPassword = async (event:any) => {
        event.preventDefault();

        const onSuccess = (response:any) => {
            if (!otcSent) {
                setOtcSent(true); // OTC sent, now show OTC input field
            } else {
                console.log('Password updated successfully');
                // Handle successful password update
            }
        };

        const onError = (error:any) => {
            console.error('Error in password reset:', error);
            // Handle error
        };

        Axios(null, 'post', `/api/auth/forgotPassword`, onSuccess, { email, newPassword, otc });
    };

    return (
        <Card type="floating" className="shadow">
            <div className="logo">
                <img className={'mb-3'} src={importer.img.require("logo.svg")} alt="" />
                <h4>Recupero Password Paziente</h4>
            </div>
            <form style={{display:'flex', flexDirection:'column', padding:'5px', justifyContent:'center', alignItems:'center'}} onSubmit={handleForgotPassword}>
                {/* Existing email input */}
                <div>
                    <CustomInput 
                        type="email" 
                        placeholder="E-Mail" 
                        className="mb-2" 
                        value={email} 
                        onChange={(newValue:any) => setEmail(newValue)} 
                        variant="cloud"
                    />
                </div>
                {/* New password input */}
                <div>
                    <CustomInput 
                        type="password" 
                        placeholder="New Password" 
                        className="mb-2" 
                        value={newPassword} 
                        onChange={(newValue:any) => setNewPassword(newValue)} 
                        variant="cloud"
                    />
                </div>


                {otcSent && (
                    // OTC Input Field
                    <div className="mb-3">
                        <CustomInput 
                            type="text" 
                            placeholder="Enter OTC" 
                            className="mb-2" 
                            value={otc} 
                            onChange={(newValue:any) => setOtc(newValue)} 
                        />
                    </div>
                )}

                <div style={{marginTop:'15px'}}><CustomButton type="submit" variant="primary" className="w-100 mb-3">Reset Password</CustomButton></div>

            </form>
            <a className='mb-2' onClick={() => navigate('/')}><strong>Back to LogIn</strong></a>
        </Card>
    );
};

export default React.memo(ForgotPassword);