import './css/PatientDashboard.css';
import React, { useState, useEffect, useContext, useRef } from 'react';

import { ctxSession } from '../store';

import Card from '../components/Card';
import Title from '../components/Title';
import CustomInput from '../components/CustomInput';
import IconButton from '@mui/material/IconButton';

import PersonAddIcon from '@mui/icons-material/PersonAdd';

import Fade from '@mui/material/Fade';

import Axios from '../helpers/axios';

import Divider from '@mui/material/Divider';

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarQuickFilter,
  GridRowSelectionModel
} from '@mui/x-data-grid';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';


import { taxCodeHTMLRegex } from '../helpers/validator';
import formDataValidator from '../helpers/formData';
import axios, { AxiosError, AxiosResponse } from 'axios';

import CustomTracker from '../components/CustomTracker'
import SimpleDropDownCheck from '../components/SimpleDropDownCheck';
import { Brush, CartesianGrid, Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import moment from 'moment';
import DoctorSelectionPopup from '../components/DoctorSelectionPopup';


const PatientDashboard = () => {

    const session = useContext(ctxSession);

    // Lab registers
    const [startLabDropdownPosition, setStartLabDropdownPosition] = useState<{ top: number, left: number } | null>(null);
    const [isLabDropdownVisible, seIstLabDropdownVisible] = useState<boolean>(false);
    const [currentPatientName, setCurrentPatientName] = useState<any>(session?.data?.userData.name);

    // Patients register
    const [patients, setPatients] = useState<any>([]);
    const selectedPatientRef = useRef<any>(null);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [isExamDropdownVisible, seIsExamDropdownVisible] = useState<boolean>(false);
    const [startExamDropdownPosition, setStartExamDropdownPosition] = useState<{ top: number, left: number } | null>(null);

    // Analysis register
    const [currentAnalysisList, setCurrentAnalysisList]  = useState<any>(null);
    const [selectedAnalysisId, setSelectedAnalysisId] = useState<any>(null);
    const [selectedAnalysisType, setSelectedAnalysisType] = useState<any>(null);
    const [chartData, setChartData] = useState<any>({
        chartData: [],
        uom: null,
        minMax: [null, null]
      });

    // Emocromo register
    const [currentEmocromoList, setCurrentEmocromoList]  = useState<any>([]);
    const [filteredEmocromoList, setFilteredEmocromoList]  = useState<any>([]);

    // Tracking register
    const [currentTrackingStatus, setCurrentTrackingStatus]  = useState<any>([]);

    // Combined data register
    const [combinedAnalysisData, setCombinedAnalysisData] = useState<any>([]);

    // Room register
    const [selectedExamRoom, setSelectedExamRoom] = useState<any>(null);

    // InstrumentData register
    const [currentInstrumentData, setCurrentInstrumentData] = useState<any>([])
    const [selectedInstrumentType, setSelectedInstrumentType] = useState<any>(null);
    const [selectedInstrumentUOM, setSelectedInstrumentUOM] = useState<any>(null);

    // Chart register
    // Sample data
    const data = [
        { timestamp: '2022-01-01T12:00:00', value: 10 },
        { timestamp: '2022-01-02T12:00:00', value: 15 },
        { timestamp: '2022-01-03T12:00:00', value: 10 },
    ];
    const examTimestamp = '2022-01-02T12:00:00'; // Example exam timestamp
    const lineColors = ['#8884d8', '#d45e6c', '#ffc658', '#83a6ed', '#8dd1e1', '#a4de6c', '#d0ed57', '#fa8c8c', '#7692ff', '#ffcb69'];
    const chartWidth = 800;
    const [chartKey, setChartKey] = useState(0);
    const handleDoubleClick = () => {
        setChartKey(prevKey => prevKey + 1);
    };

    // ExpandedAnalysisView register
    const [isExpandedAnalysisView, setIsExpandedAnalysisView] = useState<any>(null);
    const [currentExpandedAnalysisState, setCurrentExpandedAnalysisState] = useState<any>(null);
    const renderCellFunctionExpandedAnalysis = (params:any) => {
        const isObject = typeof params.row.risultato === 'object' && params.row.risultato !== null;
        const displayValue = isObject 
            ? <strong 
                className="customExpandResult" 
                onMouseOver={() => {setCurrentExpandedAnalysisState(params.row); setIsExpandedAnalysisView(true)}}
                onMouseLeave={() => setIsExpandedAnalysisView(false)}
                >
                ESPANDI RISULTATO
                </strong> 
            : <span className="customResultCell">{params.row.risultato + " " + params.row.unita_misura}</span>;
    
        return <>{displayValue}</>;
    };

    // SelectedAnalysisRegister
    const [selectedAnalysis, setSelectedAnalysis] = useState<any>(null);

    // Loading register
    const [isAnalysisLoading, setIsAnalysisLoading] = useState<any>(null);

    // TimeSeries register
    const [currentTimeSeries, setCurrentTimeSeries] = useState<any>(null);
    const [currentTransformedTimeSeries, setCurrentTransformedTimeSeries] = useState<any>(null);
    const [currentClippedTimeSeries, setCurrentClippedTimeSeries] = useState<any>(null);

    // Chart type selector
    const [currentLabName, setCurrentLabName] = useState<any>(session?.data?.userData.name);
    const [currentChartType, setCurrentChartType]  = useState<any>("GRAFICO ESAME");
    const [currentExamType, setCurrentExamType]  = useState<any>("LISTA ESAMI LABORATORIO");

    // VCF register
    const [currentVCF, setCurrentVCF] = useState<any>([]);
    const [currentExpandedVCF, setCurrentExpandedVCF] = useState<any>(null);
    const [isExpandedVCFView, setIsExpandedVCFView] = useState<any>(null);
    const renderCellFunctionExpandedVCF = (params:any) => {
        const isObject = typeof params.row.vcf === 'object' && params.row.vcf !== null;
        const displayValue = isObject 
            ? <strong 
                className="customExpandResult" 
                onClick={(e) => {e.stopPropagation(); !isExpandedVCFView ? setCurrentExpandedVCF(params.row) : setCurrentExpandedVCF(null); setIsExpandedVCFView(!isExpandedVCFView)}}
                >
                ESPANDI RISULTATO
                </strong> 
            : <span className="customResultCell">NESSUN RISULTATO</span>;
    
        return <>{displayValue}</>;
    };

    // Supervision request register
    const [showDoctorsPopup, setShowDoctorsPopup] = useState(false);
    const [availableDoctors, setAvailableDoctors] = useState([]);
    const [doctorsList, setDoctorsList] = useState<any>([]);
    useEffect(() => {
        fetchAssociatedDoctors();
    }, [session?.data?.userData?.id]);

    const fetchAssociatedDoctors = () => {
        if (session?.data?.userData?.id) {
            Axios(session, 'get', `/api/doctorPatient/GetDoctorsFromPatientId/${session.data.userData.id}`, (response:any) => {
                const transformedData = response.data.map((doc:any) => ({
                    id: doc.id_doctor, 
                    fullName: `${doc['doctor.firstname']} ${doc['doctor.lastname']}`
                }));
                setDoctorsList(transformedData);
            });
        }
    };
    const openPopup = () => {
        const onSuccess = (response:any) => {
            setAvailableDoctors(response.data);
            setShowDoctorsPopup(true);
        };
    
        Axios(session, 'get', `/api/doctorPatient/GetAvailableDoctorsFromPatientTaxCode/${session?.data?.userData?.taxCode}`, onSuccess);
    };




    // Function to format timestamp
    const formatTimestamp = (timestamp:any) => {
        return moment(timestamp).format('YYYY-MM-DD HH:mm');
    };


    /** System utilities */

    function shiftAndRebaseTimeseries(timeseries:any, targetDate:any, pointsBeforeAfter = 80) {
        if (!timeseries || timeseries.length === 0) {
            console.error("Timeseries data is null, undefined, or empty.");
            return [];
        }
    
        // Convert target date to timestamp
        const targetTimestamp = new Date(targetDate).getTime();
    
        // Sort the timeseries
        const sortedTimeseries = timeseries.sort((a:any, b:any) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
    
        // Find the index of the closest timestamp to the target date
        let closestIndex = sortedTimeseries.findIndex((item:any) => new Date(item.timestamp).getTime() >= targetTimestamp);
        if (closestIndex === -1 || new Date(sortedTimeseries[closestIndex].timestamp).getTime() > targetTimestamp) {
            closestIndex = Math.max(closestIndex - 1, 0);
        }
    
        // Calculate the time difference
        const closestTimestamp = new Date(sortedTimeseries[closestIndex].timestamp).getTime();
        const timeDiff = targetTimestamp - closestTimestamp;
    
        // Shift all timestamps
        const shiftedTimeseries = sortedTimeseries.map((item:any) => {
            const newTimestamp = new Date(new Date(item.timestamp).getTime() + timeDiff).toISOString();
            return { ...item, timestamp: newTimestamp };
        });
    
        // Calculate start and end indices to clip the timeseries
        const startIndex = Math.max(closestIndex - pointsBeforeAfter, 0);
        const endIndex = Math.min(closestIndex + pointsBeforeAfter, shiftedTimeseries.length - 1);
    
        return shiftedTimeseries.slice(startIndex, endIndex + 1);
    }
    useEffect(() => {
        if(!selectedAnalysis) return;
        const newData = shiftAndRebaseTimeseries(currentTransformedTimeSeries, selectedAnalysis.data_prelievo);
        setCurrentClippedTimeSeries(newData);
    }, [currentTransformedTimeSeries]);

    // Reset Global States
    function resetAllStates(){
        setCurrentAnalysisList(null);
        setCurrentEmocromoList(null);
        setCurrentTrackingStatus(null);
        setCombinedAnalysisData(null);
        setSelectedExamRoom(null);
        selectedPatientRef.current = null;
        setSelectionModel([]);
        setCurrentInstrumentData(null);
        setSelectedInstrumentType(null);
        setSelectedInstrumentUOM(null);
        setIsExpandedAnalysisView(null);
        setSelectedAnalysis(null);
        setCurrentTimeSeries(null);
        setCurrentTransformedTimeSeries(null);
        setCurrentClippedTimeSeries(null);
        setCurrentVCF(null);
    }

    // Reset InstrumentModel state whenever the currentAnalysisList changes
    useEffect(() => {

        setSelectedInstrumentType(null);
        setSelectedInstrumentUOM(null);

    }, [currentAnalysisList, selectedExamRoom])

    // Reset TrackingStatus whenever the InstrumentData changes
    useEffect(() => {

        setCurrentTrackingStatus(null);

    }, [currentInstrumentData])

    /** @section Fetching */

    // UserAccount filling
    useEffect(() => {

        setCurrentPatientName(session?.data?.userData.firstname + " " + session?.data?.userData.lastname);

    }, [session?.data])

    /** Fetch analysis for the logged User (Patient) */
    useEffect(( ) => {

        fetchAnalysisList();

    }, []);

    /** Fetch AnalysisList for the SelectedPatient */
    const fetchAnalysisList = (startDate?:Date | null, endDate?:Date | null, analysisType?:string) => {

        const onSuccess = (response:AxiosResponse) => {

            if( response.data ){
                
                /** @warning Type and Format check is required here when the shape is final */      

                /** Set the "id" field in the Response for DataGrid to work */
                // Transform the vcf array into a format suitable for DataGrid
                response.data.data.VCFList = response.data.data.VCFList.map((vcfAnalysis:any, index:any) => ({
                    id: index, // Unique id for each row
                    ...vcfAnalysis
                }));

                // Filter out records that are not in a Valid state
                response.data.data.AnalysisList = response.data.data.AnalysisList.filter((analysis: any) => analysis.validation !== null && analysis.validation !== false);
                response.data.data.EmocromoList = response.data.data.EmocromoList.filter((analysis: any) => analysis.validation !== null && analysis.validation !== false);

                // Set the Fetched Analysis
                setCurrentAnalysisList(response.data.data.AnalysisList);
                setCurrentEmocromoList(response.data.data.EmocromoList);
                setCurrentVCF(response.data.data.VCFList)
                console.log("Analysis fetched: ", response.data);
                
                setIsAnalysisLoading(false)
            }
        }

        const onError = (error:AxiosError) => {
            console.error("Error getting AnalysisList: ", error.message)
            setIsAnalysisLoading(false)
        }

        // Reset states
        resetAllStates();
        setIsAnalysisLoading(true)

        Axios(session, 'get', `/api/analysis/GetAnalysisListFromTaxCodePatient/${session?.data?.userData?.taxCode}`, onSuccess, onError);

    }

     /** Fetch TrackingStatus for the SelectedPatient */
    const fetchTrackingStatus = (taxCode:string, tagID:string) => {

        /// Early return if taxCode or tagID are null
        if(!taxCode || !tagID) return;

        const onSuccess = (response:AxiosResponse) => {

            if( response.data ){
                
                /** @warning Type and Format check is required here when the shape is final */

                /** Set the "id" field in the Response for DataGrid to work */
                // Map over TrackinStatus.HistoricalData and add a unique id to each item
                response.data.data.historicalData = response.data.data.historicalData.map((item:any, index:number) => ({
                    ...item,
                    id: index,
                }));

                setCurrentTrackingStatus(response.data);
                console.log("Tracking fetched: ", response.data);
                
            }
        }

        const onError = (error:AxiosError) => {
            console.error("Error getting TrackingStatus: ", error.message)
        }

        // Reset tracking status
        setCurrentTrackingStatus(null);

        Axios(session, 'get', `/api/tag/GetTrackingStatusFromTagIdPatient/${session?.data?.userData?.taxCode}/${session?.data?.userData?.id}/${tagID}`, onSuccess, onError);

    }

    /** Fetch TrackingStatus for the SelectedPatient */
    const fetchInstrumentData = (roomID:string, labID:string) => {

        /// Early return if taxCode or roomID are null
        if(!roomID) return;

        const onSuccess = (response:AxiosResponse) => {

            if( response.data ){
                
                /** @warning Type and Format check is required here when the shape is final */

                setCurrentInstrumentData(response.data);
                console.log("InstrumentData fetched: ", response.data);
                
            }
        }

        const onError = (error:AxiosError) => {
            console.error("Error getting InstrumentData: ", error.message)
        }

        // Reset instrument status
        setCurrentInstrumentData(null);

        Axios(session, 'get', `/api/environment/GetInstrumentsQueryParamsPatient/${session?.data?.userData?.taxCode}/${labID}/${roomID}`, onSuccess, onError);

    }

    /** Fetch InstrumentTimeSeries for the SelectedAnalysis */
    const fetchInstrumentTimeSeries = async () => {
        if (!currentInstrumentData || !Array.isArray(currentInstrumentData.data) || currentInstrumentData.data.length === 0) {
            console.log('No valid instrument data available');
            return;
        }
    
        let accumulatedData: any = {};
    
        const requests = currentInstrumentData.data.map((instrument: any) => {
            const url = `/api/environment/GetInstrumentsTimeSeriesPatient/${session?.data?.userData?.taxCode}/${instrument.fullName}/${encodeURIComponent(instrument.uom[0])}`;
            return axios.get(url)
                .then((response: any) => {
                    if (response?.data) {
                        response.data.data.forEach((dataPoint: any) => {
                            if(instrument.type === "carbon_dioxide") return; /// TODO: Create a selector
                            const timestamp = new Date(dataPoint[0]).toISOString();
                            if (!accumulatedData[timestamp]) {
                                accumulatedData[timestamp] = { timestamp };
                            }
                            accumulatedData[timestamp][instrument.type] = dataPoint[1];
                        });
                    }
                })
                .catch((error: any) => {
                    console.error("Error getting TimeSeries: ", error.message);
                });
        });
    
        // Wait for all requests to complete
        await Promise.all(requests);
    
        // Set the accumulated data
        setCurrentTransformedTimeSeries(Object.values(accumulatedData));
    };
    useEffect(() => {
        if (currentInstrumentData && Array.isArray(currentInstrumentData.data) && currentInstrumentData.data.length > 0) {
            fetchInstrumentTimeSeries();
        }
    }, [currentInstrumentData]);




    /** @section Combining */

    /** Combine AnalysisList and EmocromoList */
    const combineAnalysisAndPredictionData = (analysisList:any, EmocromoList:any) => {

        // Early return if EmocromoList is empty
        if (!EmocromoList || EmocromoList.length === 0) {
            return analysisList?.map((item:any, index:number) => ({
                    ...item,
                    id: index,
                }));
        }

        // Merge the two arrays
        const mergedArray = [...analysisList, ...EmocromoList];

        // Create a set to track unique id_tags
        const uniqueTags = new Set();

        // Filter out duplicates based on id_tag and assign unique ids
        const filteredMergedArray = mergedArray.filter((item: any, index: number) => {
            if (item.id_tag && uniqueTags.has(item.id_tag)) {
                return false; // Exclude duplicate
            }
            uniqueTags.add(item.id_tag);
            item.id = index; // Assigning a unique id
            return true;  // Include unique item
        });

        return filteredMergedArray;

    };

    /** Combine AnalysisList and EmocromoList */
    useEffect(() => {

        // Combine AnalysisList and EmocromoList
        const combinedData = combineAnalysisAndPredictionData(currentAnalysisList, currentEmocromoList);
        setCombinedAnalysisData(combinedData); // Update state with combined data

    }, [currentEmocromoList])


    //--------------
    /** Debug */
    useEffect(() => {

        console.log("session status changed")
        console.log(session)

    }, [session])
    //--------------


    /** @section Charting */
    
    const extractMinMax = (valoriNormali:any) => {
        let min = null, max = null;

        // Check for range format (e.g., "74 - 106")
        const rangeMatch = valoriNormali.match(/(\d+(?:[.,]\d+)?)\s*-\s*(\d+(?:[.,]\d+)?)/);
        if (rangeMatch) {
          min = parseFloat(rangeMatch[1].replace(',', '.'));
          max = parseFloat(rangeMatch[2].replace(',', '.'));
        }
        // Check for upper limit format (e.g., "< 5.0 ng/ml")
        else if (valoriNormali.includes('<')) {
          const upperLimitMatch = valoriNormali.match(/<\s*(\d+(?:[.,]\d+)?)/);
          if (upperLimitMatch) {
            max = parseFloat(upperLimitMatch[1].replace(',', '.'));
          }
        }
      
        return [min, max];
    };
    

    const processChartData = (analysisList:any, selectedAnalysisType:any) => {
        if (!selectedAnalysisType || analysisList.length === 0) {
            return { chartData: [], uom: null, minMax: [null, null] };
          }
      
        const filteredData = analysisList.filter((analysis:any) => analysis.esame === selectedAnalysisType);
        const uom = filteredData[0]?.unita_misura; // Assuming the first record's unit is the standard
      
        const chartData = filteredData.map((analysis:any, index:number) => ({
          id: analysis.id,
          value: parseFloat(analysis.risultato.replace(',', '.')), // Convert to float, handle comma as decimal
          label: `Esame ${index + 1}`,
          isSelected: analysis.id === selectedAnalysisId,
        }));
      
        const minMax = extractMinMax(filteredData[0]?.valori_normali);
        console.log(minMax)
      
        return { chartData, uom, minMax };
    };

    // Call this function when an analysis type is clicked
    const onAnalysisTypeClick = (analysisType:any) => {
        setSelectedAnalysisType(analysisType);
        const processedData = processChartData(currentAnalysisList, analysisType); 
        console.log(processedData)
        setChartData({
          chartData: processedData.chartData,
          uom: processedData.uom,
          minMax: processedData.minMax
        });
    };


    /** @section Handlers */

    const handleExamClick = (clickEvent:React.MouseEvent) => {

        // Set the GroupFilterDropdown position
        const icon = clickEvent.target as HTMLElement;
        const boundingRect = icon.getBoundingClientRect();
        setStartExamDropdownPosition({
            top: boundingRect.bottom,
            left: boundingRect.left -1
        });

        // Show Dropdown
        seIsExamDropdownVisible(true);

    }

    const handleExamSelection = (chart:any) => {

        // Set current SubLab
        if (session?.data?.userData){

            setCurrentExamType(chart);
        } 

        // Hide Dropdown
        seIsExamDropdownVisible(false);

    }


    
    /** @section Improper Components Section */

    /** TODO: Place these components properly */
    function CustomToolbar() {
        return (
        <GridToolbarContainer style={{ justifyContent: 'flex-end' }}>
            <GridToolbarFilterButton />
            <GridToolbarQuickFilter debounceMs={0}/>
        </GridToolbarContainer>
        );
    }

    function CustomNoRowsOverlay(props:any) {
        return (
          <div style={{ height: '100%', padding: 10, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {props.customText}
          </div>
        );
      }

    function getCustomNoRowsOverlay(message:string) {
        return function() {
            return <CustomNoRowsOverlay customText={message} />;
        };
    }

    // Custom Tooltip Component
    type InstrumentType = 'formaldehyde' | 'humidity' | 'particulate_matter_2_5_um' | 'temperature' | 'volatile_organic_compound';
    const typeColorMap: { [key in InstrumentType]: string } = {
        'formaldehyde': '#8884d8',
        'humidity': '#d45e6c',
        'particulate_matter_2_5_um': '#ffc658',
        'temperature': '#83a6ed',
        'volatile_organic_compound': '#8dd1e1'
    };
    const CustomTooltip = ({ active, payload, label, coordinate }: any) => {
        // Determine if tooltip should be mirrored based on cursor position
        const isMirrored = coordinate && coordinate.x > (chartWidth / 2);
        
        if (active && payload && payload.length) {
            const tooltipClass = isMirrored ? "custom-tooltip-mirrored" : "custom-tooltip";
            
            return (
                <div className={tooltipClass}>
                    <p><strong>{label}</strong></p>
                    <div className="custom-tooltip-content">
                    {payload.map((entry: any, index: any) => {
                        // Ensure entry.name is a valid instrument type
                        const instrumentType = entry.name as InstrumentType;
                        const color = typeColorMap[instrumentType] || '#8884d8'; // Default color
                            return (
                                <div key={index} style={{ color: color, padding: '2px', fontWeight: "bold" }}>
                                    {entry.value} {currentInstrumentData.data.find((instrument: any) => instrument.type === entry.name).uom}
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }
    
        return null;
    };

    const CustomLegendRenderer = ({ payload, lineColors }: any) => {
        if (!payload || !lineColors) {
            console.log("CustomLegendRenderer: payload or lineColors not provided");
            return null;
        }
    
        return (
            <ul className="custom-legend">
                {payload.map((entry: any, index: any) => (
                    <li key={`item-${index}`} className="custom-legend-item">
                        {/* SVG circle instead of a square */}
                        <svg height="10" width="10" className="custom-legend-icon">
                            <circle cx="5" cy="5" r="4" fill={lineColors[index % lineColors.length]} />
                        </svg>
                        <span className="custom-legend-text" style={{ color: lineColors[index % lineColors.length] }}>
                            {entry.value}
                        </span>
                    </li>
                ))}
            </ul>
        );
    };   


    return (
        <Fade in={true}>
            <div id="patient">
                <main>
                   
                    {/* <Card id="trackingStatus">
                        <Title  class="mb-0 marginBottomImportant ">Tracking Campione <span style={{color:"var(--blue-de-france"}}>{currentTrackingStatus?.data?.tagCode.toUpperCase()}</span></Title>
                        <CustomTracker trackingStatus={currentTrackingStatus?.data?.historicalData[currentTrackingStatus?.data?.historicalData.length - 1]?.stepPosition ?? 0}/>
                    </Card>
                    <Card id="trackingDetail">
                        <Title  class="mb-0 marginBottomImportant ">Dettaglio Tracking</Title>
                        <DataGrid
                        sx={
                                {
                                    '& .MuiDataGrid-columnHeaders': {
                                        backgroundColor: '#f6f7fb',
                                    },
                                    '& .MuiDataGrid-cell--editing': {
                                        outline: 'none'  // Remove outline from cell when editing
                                    },
                                    '& .MuiDataGrid-cell:focus': {
                                        outline: 'none'  // Remove focus outline from cell
                                    },
                                    '& .MuiDataGrid-columnHeaderTitle': {
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        flex: 1
                                    },
                                    '& .MuiDataGrid-columnHeaderTitleContainer': {
                                    justifyContent: 'center', 
                                    },
                                    '& .MuiDataGrid-cell': {
                                      textAlign: 'center', 
                                      justifyContent: 'center',
                                      display: 'flex', 
                                      alignItems: 'center'
                                    }
                                }
                            }
                            rowHeight={30}
                            columnHeaderHeight={30}
                            hideFooter={true}
                            columns={[
                                { field: 'timestamp', headerName: 'Timestamp', flex: 1},
                                { field: 'description', headerName: 'Stato Tracking', flex: 1,
                                    renderCell: ({row}) => {
                                        return <strong style={{paddingLeft:'10px', transform:'scale(0.9'}}>{row.description}</strong>
                                    }
                                 }
                            ]}
                            rows={currentTrackingStatus?.data?.historicalData ?? [{id:0, timestamp: "", description: ""}]}
                            slots={{
                                // toolbar: CustomToolbar,
                                noRowsOverlay: getCustomNoRowsOverlay("Selezionare un Campione per visualizzare il Tracking"),
                            }}
                            
                        />
                    
                    </Card> */}

                    <Card id="medicList"  >
                        <Title  class="mb-0 marginBottomImportant" ><span>Lista Medici Associati</span></Title>
                            <DataGrid
                                        // onRowClick={(params) => {}}
                                        sx={
                                                {
                                                    height: 600,

                                                    '& .MuiDataGrid-columnHeaders': {
                                                    backgroundColor: '#f6f7fb'
                                                    },
                                                    '& .MuiDataGrid-cell--editing': {
                                                        outline: 'none'  // Remove outline from cell when editing
                                                    },
                                                    '& .MuiDataGrid-cell:focus': {
                                                        outline: 'none'  // Remove focus outline from cell
                                                    },
                                                    '& .MuiDataGrid-columnHeaderTitle': {
                                                        fontWeight: 'bold',
                                                        textAlign: 'center',
                                                        flex: 1
                                                    },
                                                    '& .MuiDataGrid-columnHeaderTitleContainer': {
                                                    justifyContent: 'center', 
                                                    },
                                                    '& .MuiDataGrid-cell': {
                                                    textAlign: 'center', 
                                                    justifyContent: 'center',
                                                    display: 'flex', 
                                                    alignItems: 'center'
                                                    }
                                                }
                                            }
                                            rowHeight={50}
                                            // initialState={{
                                            //     columns: {
                                            //     columnVisibilityModel: {
                                            //         roomNumber: false,
                                            //     },
                                            //     },
                                            // }}
                                            columns={[
                                                { field: 'fullName', headerName: 'Nome Medico', flex: 1 },

                                            ]}
                                            rows={doctorsList}
                                            slots={{
                                                toolbar: CustomToolbar,
                                                // noRowsOverlay: getCustomNoRowsOverlay(!currentAnalysisList ? "" : currentAnalysisList.length === 0 ? "No Data" : "Caricamento.."),
                                            }}
                                            
                                        />
                            <h4>
                                <button onClick={openPopup} className="btn" style={{ display: 'block', margin: '0 auto' }}>
                                    Permetti Supervisione ad un Medico
                                </button>
                            </h4>      
                            {showDoctorsPopup && session?.data?.userData?.taxCode && (
                                <DoctorSelectionPopup
                                    show={showDoctorsPopup}
                                    onClose={() => setShowDoctorsPopup(false)}
                                    doctors={availableDoctors}
                                    session={session}
                                />
                            )}

                    </Card>

                     {/** EXPANDED EMOCROMO ANALYSIS AREA */}
                     <Card id={isExpandedAnalysisView ? "expandedAnalysisModalVisible" : "expandedAnalysisModalHidden"}  >
                        <Title  class="mb-0 marginBottomImportantModal ">Dettaglio Analisi {currentExpandedAnalysisState && <span style={{color:"var(--blue-de-france)"}}>
                            {currentExpandedAnalysisState?.esame?.toUpperCase() + " " + currentExpandedAnalysisState?.data_prelievo}</span>}</Title>
                            {/** Analysis result are already inside the currentExpandedAnalysisState, they have to be plotted now. All the other dashboards must be updated with this funct.*/}
                        
                            {currentExpandedAnalysisState?.risultato?.length > 0 &&
                                <div className="resultsContainer">
                                    {currentExpandedAnalysisState?.risultato?.map((item:any, index:number) => (
                                        <div key={index} className="resultItem">
                                            <strong style={{color:"var(--blue-de-france)", fontSize:'0.6rem'}}>{item.analita}</strong> 
                                            {item.valore}
                                        </div>
                                    ))}
                                </div>
                            }

                            {currentExpandedAnalysisState?.predizione &&
                                <div className="analysisDetailsContainer">
                                    

                                    <div className='predictionContainer'>

                                        <div className="containerRow"> 
                                            <span style={{fontSize:'12px'}}><strong>Predizione Esame</strong></span> 
                                        </div>

                                        <div className="containerRow"> 
                                            <div style={{padding:'10px'}}>
                                                <strong>
                                                    <span style={{ color: "var(--blue-de-france)", fontSize: currentExpandedAnalysisState?.predizione?.length > 50 ? '9px' : '12px', margin:'0 auto' }}>
                                                    {
                                                        currentExpandedAnalysisState?.predizione &&
                                                        currentExpandedAnalysisState.predizione.match(/^(.+?)\s+(\d+\.?\d*%)/)?.[1]
                                                    }
                                                    </span>
                                                </strong>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='confidenceContainer'>

                                        <div className="containerRow"> 
                                            <span style={{fontSize:'12px'}}><strong>Fattore di Confidenza</strong></span> 
                                        </div>

                                        <div className="containerRow"> 
                                            <div style={{padding:'10px'}}>
                                                <strong>
                                                    <span style={{ color: "var(--blue-de-france)", fontSize: '12px', margin:'0 auto' }}>
                                                    {
                                                        currentExpandedAnalysisState?.predizione &&
                                                        currentExpandedAnalysisState.predizione.match(/^(.+?)\s+(\d+\.?\d*%)/)?.[2]
                                                    }
                                                    </span>
                                                </strong>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                            }

                    </Card>
 
                    {/** EXPANDED VCF ANALYSIS AREA */}
                    <Card id={isExpandedVCFView ? "expandedAnalysisModalVCFVisible" : "expandedAnalysisModalVCFHidden"}  >
                        <div className='displayFlex' style={{justifyContent:'space-between'}}><Title  class="mb-0 marginBottomImportantModal ">Dettaglio Analisi {isExpandedVCFView && <span style={{color:"var(--blue-de-france)"}}>
                            {"VCF " + currentExpandedVCF.data_analisi}</span>}</Title> <Title  class="mb-0 marginBottomImportantModal "><div style={{cursor:'pointer'}} onClick={() => {setIsExpandedVCFView(false); setCurrentExpandedVCF(null)}}>X</div></Title></div>
                            {/** Analysis result are already inside the currentExpandedAnalysisState, they have to be plotted now. All the other dashboards must be updated with this funct.*/}
                        
                            {currentExpandedVCF &&
                                    <DataGrid
                                    // onRowClick={(params) => {}}
                                    sx={
                                            {
                                                height: 600,

                                                '& .MuiDataGrid-columnHeaders': {
                                                backgroundColor: '#f6f7fb'
                                                },
                                                '& .MuiDataGrid-cell--editing': {
                                                    outline: 'none'  // Remove outline from cell when editing
                                                },
                                                '& .MuiDataGrid-cell:focus': {
                                                    outline: 'none'  // Remove focus outline from cell
                                                },
                                                '& .MuiDataGrid-columnHeaderTitle': {
                                                    fontWeight: 'bold',
                                                    textAlign: 'center',
                                                    flex: 1
                                                },
                                                '& .MuiDataGrid-columnHeaderTitleContainer': {
                                                justifyContent: 'center', 
                                                },
                                                '& .MuiDataGrid-cell': {
                                                textAlign: 'center', 
                                                justifyContent: 'center',
                                                display: 'flex', 
                                                alignItems: 'center'
                                                }
                                            }
                                        }
                                        rowHeight={50}
                                        // initialState={{
                                        //     columns: {
                                        //     columnVisibilityModel: {
                                        //         roomNumber: false,
                                        //     },
                                        //     },
                                        // }}
                                        columns={[
                                            { field: 'ALT', headerName: 'ALT', flex: 1 },
                                            { field: 'CHROM', headerName: 'CHROM',  flex: 1}, 
                                            { field: 'POS', headerName: 'POS',  flex: 1},
                                            { field: 'QUAL', headerName: 'QUAL',  flex: 1},
                                            { field: 'REF', headerName: 'REF',  flex: 1},
                                            { field: 'prediction', headerName: 'Predizione',  flex: 1, renderCell: ({row}) => {
                                                return <strong className="customAnalysisResultLabel"> {row.prediction} </strong>
                                            }}, 
                                            { field: 'reason', headerName: 'Reason',  minWidth:250, renderCell: ({row}) => {
                                                return <strong className="customAnalysisResultLabel"> {row.reason} </strong>
                                            }}, 
                                            { field: 'score', headerName: 'Score',  flex: 1},


                                        ]}
                                        rows={currentExpandedVCF.vcf ? currentExpandedVCF.vcf.map((item:any, index:any) => ({ ...item, id: index })) : []}
                                        slots={{
                                            toolbar: CustomToolbar,
                                            // noRowsOverlay: getCustomNoRowsOverlay(!currentAnalysisList ? "" : currentAnalysisList.length === 0 ? "No Data" : "Caricamento.."),
                                        }}
                                        
                                    />
                            }

                    </Card>

                    <Card id="analysisList">
                    <Title  class="mb-0 marginBottomImportant "><span className="interactiveTitleDark" onClick={(e) => {handleExamClick(e)}}>{currentExamType}</span>  { currentAnalysisList && <span style={{color:"var(--blue-de-france"}}>{session?.data?.userData.taxCode.toUpperCase()}</span>}</Title>
                    {isExamDropdownVisible && 
                                    <SimpleDropDownCheck
                                        options={[{code:0, label:"LISTA ESAMI LABORATORIO"},{code:1, label:"LISTA ESAMI GENETICI"}]}
                                        onSelect={(chart:any) =>{handleExamSelection(chart.label)}}
                                        position={startExamDropdownPosition}
                                        onClose={() => {seIsExamDropdownVisible(false)}}
                                    />}

                    {/** LAB ANALYSIS */}    
                    {currentExamType === "LISTA ESAMI LABORATORIO" && 
                        <DataGrid
                        onRowClick={(params) => {
                                                fetchTrackingStatus(session?.data?.userData?.taxCode, params.row.id_tag); 
                                                setSelectedExamRoom(params.row.roomNumber)
                                                fetchInstrumentData(params.row.roomNumber, params.row.lab)
                                                params.row.esame !== "Emocromo" && setSelectedAnalysis(params.row);
                                                setCurrentTimeSeries(null);
                                                setCurrentTransformedTimeSeries(null);
                                                setCurrentClippedTimeSeries(null);
                                                isExpandedVCFView && setCurrentExpandedVCF(null); isExpandedVCFView && setIsExpandedVCFView(false)
                                                //
                                                setSelectedAnalysisId(params.row.id);
                                                onAnalysisTypeClick(params.row.esame)
                                            }}
                        sx={
                                {
                                    height: 600,

                                    '& .MuiDataGrid-columnHeaders': {
                                    backgroundColor: '#f6f7fb'
                                    },
                                    '& .MuiDataGrid-cell--editing': {
                                        outline: 'none'  // Remove outline from cell when editing
                                    },
                                    '& .MuiDataGrid-cell:focus': {
                                        outline: 'none'  // Remove focus outline from cell
                                    },
                                    '& .MuiDataGrid-columnHeaderTitle': {
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        flex: 1
                                    },
                                    '& .MuiDataGrid-columnHeaderTitleContainer': {
                                    justifyContent: 'center', 
                                    },
                                    '& .MuiDataGrid-cell': {
                                      textAlign: 'center', 
                                      justifyContent: 'center',
                                      display: 'flex', 
                                      alignItems: 'center'
                                    }
                                }
                            }
                            rowHeight={30}
                            initialState={{
                                columns: {
                                  columnVisibilityModel: {
                                    roomNumber: false,
                                    lab: false
                                  },
                                },
                              }}
                            columns={[
                                {
                                    field: 'data_prelievo',
                                    headerName: 'Data Esame',
                                    minWidth: 150,
                                },
                                { field: 'id_tag', headerName: 'ID Campione', flex: 1 },
                                { field: 'esame', headerName: 'Tipo Esame', flex: 1, renderCell: ({row}) => {
                                    return <strong className="customAnalysisResultLabel"> {row.esame} </strong>
                                }},
                                { field: 'esito', headerName: 'Esito Esame', flex: 1}, 
                                { field: 'risultato', headerName: 'Risultato Esame',  flex: 1, renderCell: renderCellFunctionExpandedAnalysis}, 
                                { field: 'valori_normali', headerName: 'Valori Normali', flex: 1}, 
                                { field: 'roomNumber', headerName: 'Stanza'},
                                { field: 'lab', headerName: 'LabID'}


                            ]}
                            rows={combinedAnalysisData ?? []}
                            slots={{
                                toolbar: CustomToolbar,
                                noRowsOverlay: getCustomNoRowsOverlay(isAnalysisLoading ? "Caricamento.." : "No Data"),
                            }}
                            // getRowClassName={(params) => {
                            //     if(params.row.validation === null) return '';
                            //     return params.row.validation ? 'valid-row' : 'invalid-row';
                            // }} 
                            
                        />
                    }

                    {/** VCF ANALYSIS */}    
                    {currentExamType === "LISTA ESAMI GENETICI" && 

                        <DataGrid
                        onRowClick={(params) => {
                            fetchTrackingStatus(params.row.CF, params.row.id_campione); 
                        }}
                        sx={
                                {
                                    height: 600,

                                    '& .MuiDataGrid-columnHeaders': {
                                    backgroundColor: '#f6f7fb'
                                    },
                                    '& .MuiDataGrid-cell--editing': {
                                        outline: 'none'  // Remove outline from cell when editing
                                    },
                                    '& .MuiDataGrid-cell:focus': {
                                        outline: 'none'  // Remove focus outline from cell
                                    },
                                    '& .MuiDataGrid-columnHeaderTitle': {
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        flex: 1
                                    },
                                    '& .MuiDataGrid-columnHeaderTitleContainer': {
                                    justifyContent: 'center', 
                                    },
                                    '& .MuiDataGrid-cell': {
                                    textAlign: 'center', 
                                    justifyContent: 'center',
                                    display: 'flex', 
                                    alignItems: 'center'
                                    }
                                }
                            }
                            rowHeight={30}
                            initialState={{
                                columns: {
                                columnVisibilityModel: {
                                    roomNumber: false,
                                },
                                },
                            }}
                            columns={[
                                {
                                    field: 'data_analisi',
                                    headerName: 'Data Esame',
                                    flex: 1,
                                },
                                { field: 'id_campione', headerName: 'ID Campione', flex: 1 },
                                { field: 'vcf', headerName: 'VCF', flex: 1, renderCell: renderCellFunctionExpandedVCF },
                                { field: '', headerName: 'SelcaGenetics', flex: 1, renderCell: ({row}) => {
                                    return <strong onClick={(e) => {e.stopPropagation(); window.open("https://genetics.selca.sicilia.it/analisi", "_blank")}} className="customAnalysisResultLabel" style={{paddingRight:'5px', cursor:'pointer'}}> LINK PIATTAFORMA </strong>
                                }}


                            ]}
                            rows={currentVCF ?? []}
                            slots={{
                                toolbar: CustomToolbar,
                                noRowsOverlay: getCustomNoRowsOverlay(isAnalysisLoading ? "Caricamento.." : "No Data"),
                            }}
                            // getRowClassName={(params) => {
                            //     if(params.row.validation === null) return '';
                            //     return params.row.validation ? 'valid-row' : 'invalid-row';
                            // }} 
                            
                        />     
                                                
                        } 
                    

                    </Card>


                    <Card id="analysisHistory">
                        <Title  class="mb-0 marginBottomImportant "><span>{"GRAFICO ESAME"}</span>  <span style={{color:'var(--blue-de-france)'}}>{selectedAnalysis?.esame}</span> | <span  onClick={(e) => {e.stopPropagation(); window.open(session?.data?.userData?.taxCode === "ZGRGDM79A09C351U" ? "https://uni-nutrizionale.vercel.app/pazienti/8" : "https://uni-nutrizionale.vercel.app/pazienti/9", "_blank")}} className="interactiveTitle">VALORI NUTRIZIONALI</span> </Title>                          
                        
                        {currentChartType === "GRAFICO ESAME" && chartData.uom &&

                            <ResponsiveContainer width="100%" height="100%">

                                <LineChart margin={{top: 20}} data={chartData.chartData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="label" />
                                    <YAxis domain={[0,  chartData.minMax[1] * 2]} unit={chartData.uom} />
                                    <Tooltip />
                                    <Line type="monotone" dataKey="value" stroke="#8884d8" dot={{ r: 5 }} activeDot={{ r: 8 }} />
                                    <Brush dataKey="label" height={30} stroke="var(--blue-de-france)" />
                                    {chartData.minMax[0] !== null && <ReferenceLine y={chartData.minMax[0]} stroke="red" label={{value: `Min: ${chartData.minMax[0]}`, position:'insideTop'}} />}
                                    {chartData.minMax[1] !== null && <ReferenceLine y={chartData.minMax[1]} stroke="red" label={{value: `Max: ${chartData.minMax[1]}`, position:'insideTop'}} />}
                                </LineChart>

                            </ResponsiveContainer>

                        }
                            

                        
                    </Card>
                        
                </main>
            </div>
        </Fade>
    );
};
export default React.memo(PatientDashboard);


// http://localhost:3001/