import './css/CustomInput.css';


import React, {useEffect, useState} from 'react';

import { Button, Tooltip } from '@mui/material';

const strengthLevels = [
    /(?=.*?[a-z])/, // at least 1 digit lowercase
    /(?=.*?[A-Z])/, // at least 1 digit uppercase
    /(?=.*?[0-9])/, // at least 1 digit nomber
    /(?=.*?[#?!@$%^&*-])/, // at least 1 digit special char
    /.{8,}/   // minimum length = 8
]


function CustomInput (props: any) {
    
    const [value, setValue] = useState<string|null>(null);
    const [focused, setFocused] = useState<boolean>(false);

    const [strengthLevel, setStrengthLevel] = useState<number>(0);


    const randID = Math.random().toString()
    // console.log(props)
    let className = "customInput ";
    switch(props.variant){
        case 'cloud': {
            className += "inputCloud ";
            break;
        }
        default:
        case 'underline': {
            className += "inputUnderline ";
            break;
        }
    }
    
    if(props.class){
        className = className + ' ' + props.class + ' ';
    }

    const selectType = () => {
        switch(props.type){
            case 'date': {
                if(focused || value){
                    return 'date';
                }else{
                    return 'text';
                }
            }
            case 'password': {
                if(showPassword){
                    return 'text';
                }else{
                    return 'password';
                }
            }
            default: {
                return props.type
            }
        }
    }

    useEffect( () => {
        if(props.type == 'password'){
            let level = 0;
            strengthLevels.forEach((v, k) => {
                if(value?.match(v)){
                    level++;
                }
            })
            // console.log(level)
            setStrengthLevel(level)
        }
    }, [value])

    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <>
                
            <div className={className + (focused ? 'focused' : '') }>
                {props.label && <label className={props.fixedLabel ? 'fixed' : 'slide ' + (focused ? 'focused' : '')} htmlFor={randID}>{props.label}</label>}
                
                <div>
                    {props.icon ? 
                        props.icon
                        :
                        <></>
                    }
                    {!props.icon && props.img ? 
                        <img src={props.img} alt="Username Icon" />
                        :
                        <></>
                    }
                    <input 
                        onBlur={() => {
                            if(!value){
                                setFocused(false)
                            }
                        }}
                        onFocus={() => {
                            setFocused(true)
                        }}
                        placeholder={!props.label && props.placeholder ? props.placeholder : ''}
                        id={randID}
                        type={selectType()}
                        name={props.name ? props.name : Math.random() }
                        pattern={props.pattern ? props.pattern : null }
                        required={props.required ? true : false}
                        onChange={(e) => {
                            setValue(e.target.value)
                            if(props.onChange){
                                props.onChange(e.target.value, e);
                            }
                        } }
                    />
                    
                </div>

                { props.showable && props.type == 'password' &&
                    <div
                        className={'eye ' + (showPassword ? 'show' : 'hide' )}
                        onClick={() =>  {
                            setShowPassword(!showPassword)
                        }}
                    >

                    </div>
                }

                
                { value && props.showStrength &&
                    <div className={`showStrength strengthLevel_${strengthLevel}`}>
                        <div>
                        </div>

                        <div>
                        </div>

                        <div>
                        </div>

                        <div>
                        </div>

                        <div>
                        </div>
                    </div> 
                }
            </div>

        </>
    );
}

export default React.memo(CustomInput);