import React, { useState, useEffect, useRef } from "react";
import ReactDOM from 'react-dom';
import './css/SimpleDropDownCheck.css'

/* COMPONENT */

const SimpleDropDownCheck = ({
    options,
    onSelect,
    position,
    onClose
}:any) => {

    const dropdownRef = useRef<HTMLDivElement>(null);
    
    // // Selection Registers
    // const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

    // Handle click outside
    useEffect(() => {
        // Change the event type to standard JavaScript MouseEvent
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Handle element click
    const handleClick = (option:any) => {
        onSelect(option);
    };

    
    // Return view
    const content = (
        <div  ref={dropdownRef} className="dropdown-container" style={{ top: position?.top, left: position?.left }}>
            <ul className="dropdown-content">
                {options.map((option:any) => (
                    <li key={option.code} className="dropdown-item" onClick={() => handleClick(option)}>
                        <div className="dropdown-checkbox">
                            {/* <input 
                                type="checkbox"
                                checked={selectedOptions.includes(option.code)}
                                // onChange={() => handleCheckboxChange(option)}
                            /> */}
                        </div>
                        <div className="dropdown-label">{option.label}</div>
                    </li>
                ))}
            </ul>
        </div>
    );

    return ReactDOM.createPortal(content, document.body);
};

export default SimpleDropDownCheck;
