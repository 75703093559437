import axios from 'axios';
import './css/LabDashboard.css';
import React, { useState, useEffect, useContext, useRef } from 'react';

import { ctxSession } from '../store';

import Card from '../components/Card';
import Title from '../components/Title';
import CustomInput from '../components/CustomInput';
import IconButton from '@mui/material/IconButton';

import PersonAddIcon from '@mui/icons-material/PersonAdd';

import Fade from '@mui/material/Fade';

import Axios from '../helpers/axios';

import Divider from '@mui/material/Divider';

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarQuickFilter,
  GridRowSelectionModel
} from '@mui/x-data-grid';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';


import { taxCodeHTMLRegex } from '../helpers/validator';
import formDataValidator from '../helpers/formData';
import { AxiosError, AxiosResponse } from 'axios';

import CustomTracker from '../components/CustomTracker'
import SimpleDropDownCheck from '../components/SimpleDropDownCheck';
import { Brush, CartesianGrid, Legend, LegendProps, Line, LineChart, Rectangle, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import moment from 'moment';
import LabsMap from '../../assets/images/labs.jpg'


const ReporterDashboard = () => {

    const session = useContext(ctxSession);

    // Lab registers
    const [startLabDropdownPosition, setStartLabDropdownPosition] = useState<{ top: number, left: number } | null>(null);
    const [isLabDropdownVisible, seIstLabDropdownVisible] = useState<boolean>(false);
    const [startChartDropdownPosition, setStartChartDropdownPosition] = useState<{ top: number, left: number } | null>(null);
    const [isChartDropdownVisible, seIsChartDropdownVisible] = useState<boolean>(false);
    const [startExamDropdownPosition, setStartExamDropdownPosition] = useState<{ top: number, left: number } | null>(null);
    const [isExamDropdownVisible, seIsExamDropdownVisible] = useState<boolean>(false);

    // Patients register
    const [patients, setPatients] = useState<any>([]);
    const selectedPatientRef = useRef<any>(null);
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);

    // Analysis register
    const [currentAnalysisList, setCurrentAnalysisList]  = useState<any>([]);

    // Emocromo register
    const [currentEmocromoList, setCurrentEmocromoList]  = useState<any>([]);

    // VCF Rrister
    const [currentVCF, setCurrentVCF] = useState<any>([]);

    // Tracking register
    const [currentTrackingStatus, setCurrentTrackingStatus]  = useState<any>([]);

    // Combined data register
    const [combinedAnalysisData, setCombinedAnalysisData] = useState<any>([]);

    // Room register
    const [selectedExamRoom, setSelectedExamRoom] = useState<any>(null);
    const [selectedSubLab, setSelectedSubLab] = useState<any>([]);

    // InstrumentData register
    const [currentInstrumentData, setCurrentInstrumentData] = useState<any>([])
    const [selectedInstrumentType, setSelectedInstrumentType] = useState<any>(null);
    const [selectedInstrumentUOM, setSelectedInstrumentUOM] = useState<any>([]);

    // ExpandedAnalysisView register
    const [isExpandedAnalysisView, setIsExpandedAnalysisView] = useState<any>(null);
    const [currentExpandedAnalysisState, setCurrentExpandedAnalysisState] = useState<any>(null);
    const renderCellFunctionExpandedAnalysis = (params:any) => {
        const isObject = typeof params.row.risultato === 'object' && params.row.risultato !== null;
        const displayValue = isObject 
            ? <strong 
                className="customExpandResult" 
                onMouseOver={() => {setCurrentExpandedAnalysisState(params.row); setIsExpandedAnalysisView(true)}}
                onMouseLeave={() => setIsExpandedAnalysisView(false)}
              >
                ESPANDI RISULTATO
              </strong> 
            : <span className="customResultCell">{params.row.risultato + " " + params.row.unita_misura}</span>;
    
        return <>{displayValue}</>;
    };

    // SelectedAnalysisRegister
    const [selectedAnalysis, setSelectedAnalysis] = useState<any>(null);

    // CHart type selector
    const [currentLabName, setCurrentLabName] = useState<any>(session?.data?.userData.name);
    const [currentChartType, setCurrentChartType]  = useState<any>("VALORI AMBIENTALI");
    const [currentExamType, setCurrentExamType]  = useState<any>("LISTA ESAMI LABORATORIO");

    // Chart register
    // Sample data
    const data = [
        { timestamp: '2022-01-01T12:00:00', value: 10 },
        { timestamp: '2022-01-02T12:00:00', value: 15 },
        { timestamp: '2022-01-03T12:00:00', value: 10 },
    ];
    const examTimestamp = '2022-01-02T12:00:00'; // Example exam timestamp
    const lineColors = ['#8884d8', '#d45e6c', '#ffc658', '#83a6ed', '#8dd1e1', '#a4de6c', '#d0ed57', '#fa8c8c', '#7692ff', '#ffcb69'];
    type InstrumentType = 'formaldehyde' | 'humidity' | 'particulate_matter_2_5_um' | 'temperature' | 'volatile_organic_compound';
    const typeColorMap: { [key in InstrumentType]: string } = {
        'formaldehyde': '#8884d8',
        'humidity': '#d45e6c',
        'particulate_matter_2_5_um': '#ffc658',
        'temperature': '#83a6ed',
        'volatile_organic_compound': '#8dd1e1'
    };
    const chartWidth = 600;
    const [chartKey, setChartKey] = useState(0);
    const handleDoubleClick = () => {
        setChartKey(prevKey => prevKey + 1);
    };

    // TimeSeries register
    const [currentTimeSeries, setCurrentTimeSeries] = useState<any>(null);
    const [currentTransformedTimeSeries, setCurrentTransformedTimeSeries] = useState<any>(null);
    const [currentClippedTimeSeries, setCurrentClippedTimeSeries] = useState<any>(null);

    // Alarms register
    const [currentInstrumentAlarms, setCurrentInstrumentAlarms] = useState<any>([])
    const [selectedMarker, setSelectedMarker] = useState<any>(null);
    const [markerDropDownPosition, setMarkerDropdownPosition] = useState<any>({ top: 0, left: 0 });

    // VCF register
    const [currentExpandedVCF, setCurrentExpandedVCF] = useState<any>(null);
    const [isExpandedVCFView, setIsExpandedVCFView] = useState<any>(null);
    const renderCellFunctionExpandedVCF = (params:any) => {
        const isObject = typeof params.row.vcf === 'object' && params.row.vcf !== null;
        const displayValue = isObject 
            ? <strong 
                className="customExpandResult" 
                onClick={() => {!isExpandedVCFView ? setCurrentExpandedVCF(params.row) : setCurrentExpandedVCF(null); setIsExpandedVCFView(!isExpandedVCFView)}}
              >
                ESPANDI RISULTATO
              </strong> 
            : <span className="customResultCell">NESSUN RISULTATO</span>;
    
        return <>{displayValue}</>;
    };
    
    enum AlarmCode {
        GREEN = 0,
        YELLOW = 1,
        ORANGE = 2,
        RED = 3
    }

    // Alert rendering map
    const AlertMapIndicator: { [key in AlarmCode]: string } = {
        [AlarmCode.GREEN]: "sensor-marker-clear-small",
        [AlarmCode.YELLOW]: "sensor-marker-yellow-small",
        [AlarmCode.ORANGE]: "sensor-marker-orange-small",
        [AlarmCode.RED]: "sensor-marker-alert-small"
    };
    
    // Loading register
    const [isAnalysisLoading, setIsAnalysisLoading] = useState<any>(null);
















    // Function to format timestamp (UTC)
    const formatTimestamp = (timestamp:any) => {
        const date = new Date(timestamp);
        const year = date.getUTCFullYear();
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const day = date.getUTCDate().toString().padStart(2, '0');
        const hour = date.getUTCHours().toString().padStart(2, '0');
        const minute = date.getUTCMinutes().toString().padStart(2, '0');
    
        return `${year}-${month}-${day} ${hour}:${minute}`;
    }

    /** System utilities */

    function shiftAndRebaseTimeseries(timeseries:any, targetDate:any, pointsBeforeAfter = 80) {
        if (!timeseries || timeseries.length === 0) {
            console.error("Timeseries data is null, undefined, or empty.");
            return [];
        }
    
        // Convert target date to timestamp
        const targetTimestamp = new Date(targetDate).getTime();
    
        // Sort the timeseries
        const sortedTimeseries = timeseries.sort((a:any, b:any) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
    
        // Find the index of the closest timestamp to the target date
        let closestIndex = sortedTimeseries.findIndex((item:any) => new Date(item.timestamp).getTime() >= targetTimestamp);
        if (closestIndex === -1 || new Date(sortedTimeseries[closestIndex].timestamp).getTime() > targetTimestamp) {
            closestIndex = Math.max(closestIndex - 1, 0);
        }
    
        // Calculate the time difference
        const closestTimestamp = new Date(sortedTimeseries[closestIndex].timestamp).getTime();
        const timeDiff = targetTimestamp - closestTimestamp;
    
        // Shift all timestamps
        const shiftedTimeseries = sortedTimeseries.map((item:any) => {
            const newTimestamp = new Date(new Date(item.timestamp).getTime() + timeDiff).toISOString();
            return { ...item, timestamp: newTimestamp };
        });
    
        // Calculate start and end indices to clip the timeseries
        const startIndex = Math.max(closestIndex - pointsBeforeAfter, 0);
        const endIndex = Math.min(closestIndex + pointsBeforeAfter, shiftedTimeseries.length - 1);
    
        return shiftedTimeseries.slice(startIndex, endIndex + 1);
    }
    useEffect(() => {
        if(!selectedAnalysis) return;
        const newData = shiftAndRebaseTimeseries(currentTransformedTimeSeries, selectedAnalysis.data_prelievo);
        setCurrentClippedTimeSeries(newData);
    }, [currentTransformedTimeSeries]);

    // Reset Global States
    function resetAllStates(){
        setCurrentAnalysisList(null);
        setCurrentEmocromoList(null);
        setCurrentTrackingStatus(null);
        setCombinedAnalysisData(null);
        setSelectedExamRoom(null);
        setSelectedSubLab(null);
        selectedPatientRef.current = null;
        setSelectionModel([]);
        setCurrentInstrumentData(null);
        setSelectedInstrumentType(null);
        setSelectedInstrumentUOM(null);
        setIsExpandedAnalysisView(null);
        setSelectedAnalysis(null);
        setCurrentTimeSeries(null);
        setCurrentTransformedTimeSeries(null);
        setCurrentClippedTimeSeries(null);
        setCurrentVCF(null);
    }

    // Reset InstrumentModel state whenever the currentAnalysisList changes
    useEffect(() => {

        setSelectedInstrumentType(null);
        setSelectedInstrumentUOM(null);

    }, [currentAnalysisList, selectedExamRoom])

    // Reset TrackingStatus whenever the InstrumentData changes
    useEffect(() => {

        setCurrentTrackingStatus(null);

    }, [currentInstrumentData])



    /** @section Fetching */

    // Show UserAccount
    useEffect(() => {

        console.log("Account type:", session?.data?.userData?.accountType)
        console.log("Account role:", session?.data?.userData?.roles[0])
        
    }, [session?.data?.userData?.accountType])

    /** Fetch patients for the logged User every time the LabCode changes (as when switching between labs) */
    useEffect(( ) => {

        if(session?.data?.userData.code === "0") return;

        const onSuccess = (response:AxiosResponse) => {
            if( response.data ){

                /** Set the "id" field in the Response for DataGrid to work */
                // Map over AnalysisList and add a unique id to each item
                response.data.data = response.data.data.map((item:any, index:number) => ({
                    ...item,
                    id: index,
                }));

                /** @warning Type and Format check is required here when the shape is final */
                setPatients( response.data )
                console.log("Patients fetched: ", response.data)

            }
        }

        const onError = (error:AxiosError) => {
            console.error("Error getting Patients: ", error.message)
        }

        resetAllStates();

        Axios(session, 'get', `/api/lab/GetPatients/${session?.data?.userData?.code}`, onSuccess, onError);

    }, [session?.data?.userData?.code]);

    /** Fetch AnalysisList for the SelectedPatient */
    const fetchAnalysisList = (taxCode:string, startDate?:Date | null, endDate?:Date | null, analysisType?:string) => {

        const onSuccess = (response:AxiosResponse) => {

            if( response.data ){
                
                /** @warning Type and Format check is required here when the shape is final */

                // Get current patient ID
                const currentPatient = patients.data.find((patient: any) => patient.codiceFiscale === taxCode);

                /** Set the "id" field in the Response for DataGrid to work */
                // Transform the vcf array into a format suitable for DataGrid
                response.data.data.VCFList = response.data.data.VCFList.map((vcfAnalysis:any, index:any) => ({
                    id: index, // Unique id for each row
                    ...vcfAnalysis
                }));

                console.log(response.data.data.VCFList)

                setCurrentAnalysisList(response.data.data.AnalysisList);
                setCurrentEmocromoList(response.data.data.EmocromoList);
                setCurrentVCF(response.data.data.VCFList)
                console.log("Analysis fetched: ", response.data);

                // Set current Patient as Selected
                selectedPatientRef.current = currentPatient;

                setIsAnalysisLoading(false)
                
            }
        }

        const onError = (error:AxiosError) => {
            console.error("Error getting AnalysisList: ", error.message)
            setIsAnalysisLoading(false)
        }

        // Reset states
        resetAllStates();
        setIsAnalysisLoading(true)

        Axios(session, 'get', `/api/analysis/GetAnalysisListFromTaxCodeLab/${taxCode}/${session?.data?.userData?.code}`, onSuccess, onError);

    }

    /** Fetch TrackingStatus for the SelectedPatient */
    const fetchTrackingStatus = (taxCode:string, tagID:string) => {

        /// Early return if taxCode or tagID are null
        if(!taxCode || !tagID) return;

        const onSuccess = (response:AxiosResponse) => {

            if( response.data ){
                
                /** @warning Type and Format check is required here when the shape is final */

                /** Set the "id" field in the Response for DataGrid to work */
                // Map over TrackinStatus.HistoricalData and add a unique id to each item
                response.data.data.historicalData = response.data.data.historicalData.map((item:any, index:number) => ({
                    ...item,
                    id: index,
                }));

                setCurrentTrackingStatus(response.data);
                console.log("Tracking fetched: ", response.data);
                
            }
        }

        const onError = (error:AxiosError) => {
            console.error("Error getting TrackingStatus: ", error.message)
        }

        // Reset tracking status
        setCurrentTrackingStatus(null);

        Axios(session, 'get', `/api/tag/GetTrackingStatusFromTagIdLab/${taxCode}/${session?.data?.userData?.code}/${tagID}`, onSuccess, onError);

    }

    /** Fetch InstrumentData for the SelectedAnalysis */
    const fetchInstrumentData = (roomID:string) => {

        /// Early return if taxCode or roomID are null
        if(!roomID) return;

        const onSuccess = (response:AxiosResponse) => {

            if( response.data ){
                
                /** @warning Type and Format check is required here when the shape is final */

                setCurrentInstrumentData(response.data);
                console.log("InstrumentData fetched: ", response.data);
                
            }
        }

        const onError = (error:AxiosError) => {
            console.error("Error getting InstrumentData: ", error.message)
        }

        // Reset instrument status
        setCurrentInstrumentData(null);

        Axios(session, 'get', `/api/environment/GetInstrumentsQueryParamsLab/${session?.data?.userData?.code}/${roomID}`, onSuccess, onError);

    }

    /** Fetch InstrumentTimeSeries for the SelectedAnalysis */
    const fetchInstrumentTimeSeries = async () => {
        if (!currentInstrumentData || !Array.isArray(currentInstrumentData.data) || currentInstrumentData.data.length === 0) {
            console.log('No valid instrument data available');
            return;
        }
    
        let accumulatedData: any = {};
    
        const requests = currentInstrumentData.data.map((instrument: any) => {
            const url = `/api/environment/GetInstrumentsTimeSeriesLab/${session?.data?.userData?.code}/${instrument.fullName}/${encodeURIComponent(instrument.uom[0])}`;
            return axios.get(url, {headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}})
                .then((response: any) => {
                    if (response?.data) {
                        response.data.data.forEach((dataPoint: any) => {
                            if(instrument.type === "carbon_dioxide") return; /// TODO: Create a selector
                            const timestamp = new Date(dataPoint[0]).toISOString();
                            if (!accumulatedData[timestamp]) {
                                accumulatedData[timestamp] = { timestamp };
                            }
                            accumulatedData[timestamp][instrument.type] = dataPoint[1];
                        });
                    }
                })
                .catch((error: any) => {
                    console.error("Error getting TimeSeries: ", error.message);
                });
        });
    
        // Wait for all requests to complete
        await Promise.all(requests);
    
        // Set the accumulated data
        setCurrentTransformedTimeSeries(Object.values(accumulatedData));
    };
    useEffect(() => {
        if (currentInstrumentData && Array.isArray(currentInstrumentData.data) && currentInstrumentData.data.length > 0) {
            fetchInstrumentTimeSeries();
        }
    }, [currentInstrumentData]);

    /** Fetch InstrumentTimeSeries for the SelectedAnalysis */
    const fetchInstrumentAlarms = async () => {
        if (!currentInstrumentData || !Array.isArray(currentInstrumentData.data) || currentInstrumentData.data.length === 0) {
            console.log('No valid instrument data available');
            return;
        }
        
        const url = `/api/environment/GetAlertsFromInstrumentId/${currentInstrumentData?.data[0].model}_${currentInstrumentData?.data[0].labID}.${currentInstrumentData?.data[0].roomNumber}`;
        return axios.get(url, {headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}})
            .then((response: any) => {
                if (response?.data) {

                    // Set the Alarms data
                    setCurrentInstrumentAlarms(response.data);
                    console.log("Alarms downloaded", response.data)
                }
            })
            .catch((error: any) => {
                console.error("Error getting InstrumentAlarms: ", error.message);
            });

    };
    useEffect(() => {
        if (currentInstrumentData && Array.isArray(currentInstrumentData.data) && currentInstrumentData.data.length > 0) {
            fetchInstrumentAlarms();
        }
    }, [currentInstrumentData]);

    
    

    /** @section Combining */

    /** Combine AnalysisList and EmocromoList */
    const combineAnalysisAndPredictionData = (analysisList:any, EmocromoList:any) => {

        // Early return if EmocromoList or VCFList is empty
        if (!EmocromoList || EmocromoList.length === 0) {
            return analysisList?.map((item:any, index:number) => ({
                    ...item,
                    id: index,
                }));
        }

        // Merge the two arrays
        const mergedArray = [...analysisList, ...EmocromoList];

        // Create a set to track unique id_tags
        const uniqueTags = new Set();

        // Filter out duplicates based on id_tag and assign unique ids
        const filteredMergedArray = mergedArray.filter((item: any, index: number) => {
            if (item.id_tag && uniqueTags.has(item.id_tag)) {
                return false; // Exclude duplicate
            }
            uniqueTags.add(item.id_tag);
            item.id = index; // Assigning a unique id
            return true;  // Include unique item
        });

        return filteredMergedArray;

    };
    

    /** Combine AnalysisList and EmocromoList */
    useEffect(() => {

        // Combine AnalysisList and EmocromoList
        const combinedData = combineAnalysisAndPredictionData(currentAnalysisList, currentEmocromoList);
        setCombinedAnalysisData(combinedData); // Update state with combined data

    }, [currentEmocromoList])




    /** Debug */
    useEffect(() => {

        console.log("combinedAnalysisData status changed")
        console.log(combinedAnalysisData)

    }, [combinedAnalysisData])


    /** @section Posting */

    /** Post ExamValidation to the backend database */
    const postExamValidation = (exam:any, validation:boolean) => {

        const onSuccess = (response:AxiosResponse) => {

            if( response.data ){
                
                console.log("ExamValidation posted: ", response.data);
                
            }
        }

        const onError = (error:AxiosError) => {
            console.error("Error posting ExamValidation: ", error.message)
        }

        Axios(session, 'post', `/api/analysis/PostValidateExam/${session?.data?.userData?.code}/${encodeURIComponent(JSON.stringify(exam))}/${validation}`, onSuccess, onError);

    }



    /** @section Handlers */

    const handleMarkerClick = (marker:any, clickEvent:React.MouseEvent) => {
        const icon = clickEvent.target as HTMLElement;
        const boundingRect = icon.getBoundingClientRect();
        const markerPosition = {
            top: boundingRect.bottom,
            left: boundingRect.left -1
        };
        setSelectedMarker(marker);
        setMarkerDropdownPosition(markerPosition);
    };
    

    const handleLabClick = (clickEvent:React.MouseEvent) => {

        // Set the GroupFilterDropdown position
        const icon = clickEvent.target as HTMLElement;
        const boundingRect = icon.getBoundingClientRect();
        setStartLabDropdownPosition({
            top: boundingRect.bottom,
            left: boundingRect.left -1
        });

        // Show Dropdown
        seIstLabDropdownVisible(true);

    }

    const handleLabSelection = (lab:any) => {

        // Set current SubLab
        if (session?.data?.userData){
            session.data.userData.code = lab.code;
            session.data.userData.name = lab.label;
            setCurrentLabName(session.data.userData.name);
        } 

        // Hide Dropdown
        seIstLabDropdownVisible(false);

    }

    const handleChartClick = (clickEvent:React.MouseEvent) => {

        // Set the GroupFilterDropdown position
        const icon = clickEvent.target as HTMLElement;
        const boundingRect = icon.getBoundingClientRect();
        setStartChartDropdownPosition({
            top: boundingRect.bottom,
            left: boundingRect.left -1
        });

        // Show Dropdown
        seIsChartDropdownVisible(true);

    }

    const handleChartSelection = (chart:any) => {

        // Set current SubLab
        if (session?.data?.userData){

            setCurrentChartType(chart);
        } 

        // Hide Dropdown
        seIsChartDropdownVisible(false);

    }

    const handleExamClick = (clickEvent:React.MouseEvent) => {

        // Set the GroupFilterDropdown position
        const icon = clickEvent.target as HTMLElement;
        const boundingRect = icon.getBoundingClientRect();
        setStartExamDropdownPosition({
            top: boundingRect.bottom,
            left: boundingRect.left -1
        });

        // Show Dropdown
        seIsExamDropdownVisible(true);

    }

    const handleExamSelection = (chart:any) => {

        // Set current SubLab
        if (session?.data?.userData){

            setCurrentExamType(chart);
        } 

        // Hide Dropdown
        seIsExamDropdownVisible(false);

    }

    

    /** @section Improper Components Section */

    /** TODO: Place these components properly */
    function CustomToolbar() {
        return (
        <GridToolbarContainer style={{ justifyContent: 'flex-end' }}>
            <GridToolbarFilterButton />
            <GridToolbarQuickFilter debounceMs={0}/>
        </GridToolbarContainer>
        );
    }

    function CustomNoRowsOverlay(props:any) {
        return (
          <div style={{ height: '100%', padding: 10, textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {props.customText}
          </div>
        );
      }

    function getCustomNoRowsOverlay(message:string) {
        return function() {
            return <CustomNoRowsOverlay customText={message} />;
        };
    }

    // Custom Tooltip Component
    const CustomTooltip = ({ active, payload, label, coordinate }: any) => {
        // Determine if tooltip should be mirrored based on cursor position
        const isMirrored = coordinate && coordinate.x > (chartWidth / 2);
        
        if (active && payload && payload.length) {
            const tooltipClass = isMirrored ? "custom-tooltip-mirrored" : "custom-tooltip";
            
            return (
                <div className={tooltipClass}>
                    <p><strong>{label}</strong></p>
                    <div className="custom-tooltip-content">
                    {payload.map((entry: any, index: any) => {
                        // Ensure entry.name is a valid instrument type
                        const instrumentType = entry.name as InstrumentType;
                        const color = typeColorMap[instrumentType] || '#8884d8'; // Default color
                            return (
                                <div key={index} style={{ color: color, padding: '2px', fontWeight: "bold" }}>
                                    {entry.value} {currentInstrumentData.data.find((instrument: any) => instrument.type === entry.name).uom}
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }
    
        return null;
    };
    

    const CustomLegendRenderer = ({ payload, lineColors }: any) => {
        if (!payload || !lineColors) {
            console.log("CustomLegendRenderer: payload or lineColors not provided");
            return null;
        }
    
        return (
            <ul className="custom-legend">
                {payload.map((entry: any, index: any) => (
                    <li key={`item-${index}`} className="custom-legend-item">
                        {/* SVG circle instead of a square */}
                        <svg height="10" width="10" className="custom-legend-icon">
                            <circle cx="5" cy="5" r="4" fill={lineColors[index % lineColors.length]} />
                        </svg>
                        <span className="custom-legend-text" style={{ color: lineColors[index % lineColors.length] }}>
                            {entry.value}
                        </span>
                    </li>
                ))}
            </ul>
        );
    };    
  

    const invitePatient = (e:React.FormEvent<EventTarget>) => {
        e.preventDefault();

        
        const formData = formDataValidator(e.target);
        console.log(formData)
    }


    return (
        <Fade in={true}>
            <div id="lab">
                <main>

                    {/** PATIENTS LIST AREA */}
                    <Card  id="patientsList">

                        {/* <Title  class="mb-0">Aggiungi Paziente</Title>
                            <form id="addPatient" onSubmit={invitePatient}>
                                <CustomInput
                                    type="text"
                                    name="tax_code"
                                    variant="cloud"
                                    pattern={taxCodeHTMLRegex}
                                    placeholder="Codice Fiscale"
                                    class="mb-2"
                                    required>
                                    
                                </CustomInput>
                                
                                <IconButton type="submit" size="small" onClick={() => true}>
                                    <PersonAddIcon/>
                                </IconButton>
                            </form> */}

                        {/* <Divider /> */}

                        <Title  class="mb-0 marginBottomImportant">Lista Pazienti <span className={"interactiveTitle"} onClick={(e) => {handleLabClick(e)}}>LABORATORIO {currentLabName?.toUpperCase()}</span></Title>
                        {session?.data?.userData?.labs?.length > 0 && isLabDropdownVisible && 
                                <SimpleDropDownCheck
                                    options={session?.data?.userData?.labs}
                                    onSelect={(lab:any) =>{handleLabSelection(lab)}}
                                    position={startLabDropdownPosition}
                                    onClose={() => {seIstLabDropdownVisible(false)}}
                                />}

                            <DataGrid
                            onRowClick={(params) => {
                                fetchAnalysisList(params.row.codiceFiscale);
                                isExpandedVCFView && setCurrentExpandedVCF(null); isExpandedVCFView && setIsExpandedVCFView(false)
                            }}
                            rowSelectionModel={selectionModel}
                            onRowSelectionModelChange={(newSelection:GridRowSelectionModel) => setSelectionModel(newSelection)}
                            sx={
                                    {
                                        '& .MuiDataGrid-columnHeaders': {
                                            backgroundColor: '#f6f7fb'
                                        },
                                        '& .MuiDataGrid-cell--editing': {
                                            outline: 'none'  // Remove outline from cell when editing
                                        },
                                        '& .MuiDataGrid-cell:focus': {
                                            outline: 'none'  // Remove focus outline from cell
                                        },
                                        '& .MuiDataGrid-columnHeaderTitle': {
                                            fontWeight: 'bold',
                                            textAlign: 'center',
                                            flex: 1
                                        },
                                        '& .MuiDataGrid-columnHeaderTitleContainer': {
                                            justifyContent: 'center', 
                                        },
                                        '& .MuiDataGrid-cell': {
                                            textAlign: 'center', 
                                            justifyContent: 'center',
                                            display: 'flex', 
                                            alignItems: 'center', 
                                        }
                                    }
                                }
                                rowHeight={30}
                                columns={[
                                    { field: 'id', headerName: 'ID', width: 60},
                                    {
                                        field: 'nome',
                                        headerName: 'Paziente',
                                        minWidth: 150,
                                        renderCell: ({row}) => {
                                            return `${row.nome} ${row.cognome}`
                                        }
                                    },
                                    { field: 'codiceFiscale', headerName: 'CF', minWidth: 160 },
                                    { field: 'dataNascita', headerName: 'Data di Nascita', minWidth: 120 },
                                    { field: 'sesso', headerName: 'Sesso', flex: 1},
                                ]}
                                rows={patients.data ?? []}
                                slots={{
                                    toolbar: CustomToolbar,
                                    noRowsOverlay: getCustomNoRowsOverlay("Selezionare un Laboratorio per visualizzare i Pazienti")
                                }}

                                // onRowSelectionModelChange={(newRowSelectionModel) => {
                                //     console.log(data, newRowSelectionModel)
                                //     const test = data.rows.filter((tuple) => tuple.id === newRowSelectionModel[0])
                                //     console.log(test)
                                // }}
                            />
                                      

                    </Card>


                    {/** TRACKING AREA TODO: Align the step display*/}
                    <Card id="trackingStatus">
                        <Title  class="mb-0 marginBottomImportant ">Tracking Campione <span style={{color:"var(--blue-de-france"}}>{currentTrackingStatus?.data?.tagCode.toUpperCase()}</span></Title>
                        <CustomTracker trackingStatus={currentTrackingStatus?.data?.historicalData[currentTrackingStatus?.data?.historicalData.length - 1]?.stepPosition ?? 0}/>
                    </Card>
                    <Card id="trackingDetail">
                        {/* <Title  class="mb-0 marginBottomImportant ">Dettaglio Tracking Campione <span style={{color:"var(--blue-de-france"}}>{currentTrackingStatus?.data?.tagCode.toUpperCase()}</span></Title> */}
                        <Title  class="mb-0 marginBottomImportant ">Dettaglio Tracking</Title>
                        <DataGrid
                        // pageSize={10}
                        sx={
                                {
                                    '& .MuiDataGrid-columnHeaders': {
                                        backgroundColor: '#f6f7fb',
                                    },
                                    '& .MuiDataGrid-cell--editing': {
                                        outline: 'none'  // Remove outline from cell when editing
                                    },
                                    '& .MuiDataGrid-cell:focus': {
                                        outline: 'none'  // Remove focus outline from cell
                                    },
                                    '& .MuiDataGrid-columnHeaderTitle': {
                                        fontWeight: 'bold',
                                        textAlign: 'center',
                                        flex: 1
                                    },
                                    '& .MuiDataGrid-columnHeaderTitleContainer': {
                                    justifyContent: 'center', 
                                    },
                                    '& .MuiDataGrid-cell': {
                                    textAlign: 'center', 
                                    justifyContent: 'center',
                                    display: 'flex', 
                                    alignItems: 'center'
                                    }
                                }
                            }
                            rowHeight={30}
                            columnHeaderHeight={30}
                            hideFooter={true}
                            columns={[
                                { field: 'timestamp', headerName: 'Timestamp', flex: 1},
                                { field: 'description', headerName: 'Stato Tracking', flex: 1,
                                    renderCell: ({row}) => {
                                        return <strong style={{paddingLeft:'10px', transform:'scale(0.8'}}>{row.description}</strong>
                                    }
                                }
                            ]}
                            rows={currentTrackingStatus?.data?.historicalData ?? [{id:0, timestamp: "", description: ""}]}
                            slots={{
                                // toolbar: CustomToolbar,
                                noRowsOverlay: getCustomNoRowsOverlay("Selezionare un Campione per visualizzare il Tracking"),
                            }}
                            
                        />
                    
                    </Card>


                    {/** EXPANDED EMOCROMO ANALYSIS AREA */}
                    <Card id={isExpandedAnalysisView ? "expandedAnalysisModalVisible" : "expandedAnalysisModalHidden"}  >
                        <Title  class="mb-0 marginBottomImportantModal ">Dettaglio Analisi {currentExpandedAnalysisState && <span style={{color:"var(--blue-de-france)"}}>
                            {currentExpandedAnalysisState?.esame?.toUpperCase() + " " + currentExpandedAnalysisState?.data_prelievo}</span>}</Title>
                            {/** Analysis result are already inside the currentExpandedAnalysisState, they have to be plotted now. All the other dashboards must be updated with this funct.*/}
                        
                            {currentExpandedAnalysisState?.risultato?.length > 0 &&
                                <div className="resultsContainer">
                                    {currentExpandedAnalysisState?.risultato?.map((item:any, index:number) => (
                                        <div key={index} className="resultItem">
                                            <strong style={{color:"var(--blue-de-france)", fontSize:'0.6rem'}}>{item.analita}</strong> 
                                            {item.valore}
                                        </div>
                                    ))}
                                </div>
                            }

                            {currentExpandedAnalysisState?.predizione &&
                                <div className="analysisDetailsContainer">
                                    

                                    <div className='predictionContainer'>

                                        <div className="containerRow"> 
                                            <span style={{fontSize:'12px'}}><strong>Predizione Esame</strong></span> 
                                        </div>

                                        <div className="containerRow"> 
                                            <div style={{padding:'10px'}}>
                                                <strong>
                                                    <span style={{ color: "var(--blue-de-france)", fontSize: '12px', margin:'0 auto' }}>
                                                    {
                                                        currentExpandedAnalysisState?.predizione &&
                                                        currentExpandedAnalysisState.predizione.match(/^(.+?)\s+(\d+\.?\d*%)/)?.[1]
                                                    }
                                                    </span>
                                                </strong>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='confidenceContainer'>

                                        <div className="containerRow"> 
                                            <span style={{fontSize:'12px'}}><strong>Fattore di Confidenza</strong></span> 
                                        </div>

                                        <div className="containerRow"> 
                                            <div style={{padding:'10px'}}>
                                                <strong>
                                                    <span style={{ color: "var(--blue-de-france)", fontSize: '12px', margin:'0 auto' }}>
                                                    {
                                                        currentExpandedAnalysisState?.predizione &&
                                                        currentExpandedAnalysisState.predizione.match(/^(.+?)\s+(\d+\.?\d*%)/)?.[2]
                                                    }
                                                    </span>
                                                </strong>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                            }

                    </Card>

                    {/** EXPANDED VCF ANALYSIS AREA */}
                    <Card id={isExpandedVCFView ? "expandedAnalysisModalVCFVisible" : "expandedAnalysisModalVCFHidden"}  >
                        <div className='displayFlex' style={{justifyContent:'space-between'}}><Title  class="mb-0 marginBottomImportantModal ">Dettaglio Analisi {isExpandedVCFView && <span style={{color:"var(--blue-de-france)"}}>
                            {"VCF " + currentExpandedVCF.data_analisi}</span>}</Title> <Title  class="mb-0 marginBottomImportantModal "><div style={{cursor:'pointer'}} onClick={() => {setIsExpandedVCFView(false); setCurrentExpandedVCF(null)}}>X</div></Title></div>
                            {/** Analysis result are already inside the currentExpandedAnalysisState, they have to be plotted now. All the other dashboards must be updated with this funct.*/}
                        
                            {currentExpandedVCF &&
                                    <DataGrid
                                    // onRowClick={(params) => {}}
                                    sx={
                                            {
                                                height: 600,

                                                '& .MuiDataGrid-columnHeaders': {
                                                backgroundColor: '#f6f7fb'
                                                },
                                                '& .MuiDataGrid-cell--editing': {
                                                    outline: 'none'  // Remove outline from cell when editing
                                                },
                                                '& .MuiDataGrid-cell:focus': {
                                                    outline: 'none'  // Remove focus outline from cell
                                                },
                                                '& .MuiDataGrid-columnHeaderTitle': {
                                                    fontWeight: 'bold',
                                                    textAlign: 'center',
                                                    flex: 1
                                                },
                                                '& .MuiDataGrid-columnHeaderTitleContainer': {
                                                justifyContent: 'center', 
                                                },
                                                '& .MuiDataGrid-cell': {
                                                textAlign: 'center', 
                                                justifyContent: 'center',
                                                display: 'flex', 
                                                alignItems: 'center'
                                                }
                                            }
                                        }
                                        rowHeight={50}
                                        // initialState={{
                                        //     columns: {
                                        //     columnVisibilityModel: {
                                        //         roomNumber: false,
                                        //     },
                                        //     },
                                        // }}
                                        columns={[
                                            { field: 'ALT', headerName: 'ALT', flex: 1 },
                                            { field: 'CHROM', headerName: 'CHROM',  flex: 1}, 
                                            { field: 'POS', headerName: 'POS',  flex: 1},
                                            { field: 'QUAL', headerName: 'QUAL',  flex: 1},
                                            { field: 'REF', headerName: 'REF',  flex: 1},
                                            { field: 'prediction', headerName: 'Predizione',  flex: 1, renderCell: ({row}) => {
                                                return <strong className="customPredictionResultLabel" style={{ color:`${row.predizione && Number(row.predizione?.match(/^(.+?)\s+(\d+\.?\d*%)/)?.[2].replace("%", "")) >= 50 ? "" : "black"}` }}> {row.predizione ? Number(row.predizione?.match(/^(.+?)\s+(\d+\.?\d*%)/)?.[2].replace("%", "")) >= 50 ? "DISPONIBILE" : "DISPONIBILE (NDS)" : "NON DISPONIBILE"} </strong>
                                            }}, 
                                            { field: 'reason', headerName: 'Reason',  minWidth:250, renderCell: ({row}) => {
                                                return <strong className="customAnalysisResultLabel"> {row.reason} </strong>
                                            }}, 
                                            { field: 'score', headerName: 'Score',  flex: 1},


                                        ]}
                                        rows={currentExpandedVCF.vcf ? currentExpandedVCF.vcf.map((item:any, index:any) => ({ ...item, id: index })) : []}
                                        slots={{
                                            toolbar: CustomToolbar,
                                            // noRowsOverlay: getCustomNoRowsOverlay(!currentAnalysisList ? "" : currentAnalysisList.length === 0 ? "No Data" : "Caricamento.."),
                                        }}
                                        
                                    />
                            }

                    </Card>
                  
                    {/** ANALYSIS LIST AREA */}
                    <Card id="analysisList">
                        <Title  class="mb-0 marginBottomImportant "><span>{currentExamType}</span>  { currentAnalysisList && <span style={{color:"var(--blue-de-france"}}>{selectedPatientRef.current?.codiceFiscale.toUpperCase()}</span>}</Title>
                            {/** LAB ANALYSIS */}    
                            {currentExamType === "LISTA ESAMI LABORATORIO" && 
                                <DataGrid
                                onRowClick={(params) => {
                                                        fetchTrackingStatus(selectedPatientRef.current.codiceFiscale, params.row.id_tag); 
                                                        setSelectedExamRoom(params.row.roomNumber);
                                                        setSelectedSubLab(params.row.lab);
                                                        fetchInstrumentData(params.row.roomNumber);
                                                        setSelectedAnalysis(params.row);
                                                        setCurrentTimeSeries(null);
                                                        setCurrentTransformedTimeSeries(null);
                                                        setCurrentClippedTimeSeries(null);
                                                        isExpandedVCFView && setCurrentExpandedVCF(null); isExpandedVCFView && setIsExpandedVCFView(false)
                                                    }}
                                sx={
                                        {
                                            height: 600,

                                            '& .MuiDataGrid-columnHeaders': {
                                            backgroundColor: '#f6f7fb'
                                            },
                                            '& .MuiDataGrid-cell--editing': {
                                                outline: 'none'  // Remove outline from cell when editing
                                            },
                                            '& .MuiDataGrid-cell:focus': {
                                                outline: 'none'  // Remove focus outline from cell
                                            },
                                            '& .MuiDataGrid-columnHeaderTitle': {
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                                flex: 1
                                            },
                                            '& .MuiDataGrid-columnHeaderTitleContainer': {
                                            justifyContent: 'center', 
                                            },
                                            '& .MuiDataGrid-cell': {
                                            textAlign: 'center', 
                                            justifyContent: 'center',
                                            display: 'flex', 
                                            alignItems: 'center'
                                            }
                                        }
                                    }
                                    rowHeight={60}
                                    initialState={{
                                        columns: {
                                        columnVisibilityModel: {
                                            roomNumber: false,
                                        },
                                        },
                                    }}
                                    columns={[
                                        {
                                            field: 'data_prelievo',
                                            headerName: 'Data Esame',
                                            minWidth: 150,
                                        },
                                        { field: 'id_tag', headerName: 'ID Campione', flex: 1 },
                                        { field: 'esame', headerName: 'Tipo Esame', flex: 1, renderCell: ({row}) => {
                                            return <strong className="customAnalysisResultLabel" style={{paddingRight:'5px'}}> {row.esame.toUpperCase()} </strong>
                                        }},
                                        { field: 'esito', headerName: 'Esito Esame',  flex: 1}, 
                                        { field: 'risultato', headerName: 'Risultato Esame',  flex: 1, renderCell: renderCellFunctionExpandedAnalysis}, 
                                        { field: 'valori_normali', headerName: 'Valori Normali',  flex: 1}, 
                                        {
                                            field: '',
                                            headerName: 'Convalida Esame',
                                            flex: 1,
                                            renderCell: ({ row }) => {
                                                if (session?.data?.userData?.roles[0] === 'reporter') {

                                                    if (row.validation !== null && row.validation !== undefined) {
                                                        return (
                                                            <strong
                                                                style={{ color: row.validation ? 'var(--green-2)' : 'var(--sizzling-red)' }}
                                                            >
                                                                {row.validation ? 'VALIDO' : 'INVALIDO'}
                                                            </strong>
                                                        );
                                                    } else {
                                                        return (
                                                            <div className='displayFlexColumn'>

                                                                <button className="rejectButton" onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    const examData = {
                                                                        exam_type: row.esame,
                                                                        exam_date: row.data_prelievo,
                                                                        exam_result: row.esito ?? JSON.stringify(row.risultato),
                                                                        patient_TC: row.cf
                                                                    };
                                                                    postExamValidation(examData, false);

                                                                    // Update validation status in gridData
                                                                    const updatedData = combinedAnalysisData.map((item:any) => {
                                                                        if (item.id === row.id) {
                                                                            return { ...item, validation: false };
                                                                        }
                                                                        return item;
                                                                    });
                                                                    setCombinedAnalysisData(updatedData);

                                                                }}>Invalida Esame</button>

                                                                <button className="validateButton"onClick={(e) => {
                                                                    e.stopPropagation();

                                                                    // Post ExamValidation
                                                                    const examData = {
                                                                        exam_type: row.esame,
                                                                        exam_date: row.data_prelievo,
                                                                        exam_result: row.esito ??  JSON.stringify(row.risultato),
                                                                        patient_TC: row.cf
                                                                    };
                                                                    postExamValidation(examData, true);

                                                                    // Update validation status in gridData
                                                                    const updatedData = combinedAnalysisData.map((item:any) => {
                                                                        if (item.id === row.id) {
                                                                            return { ...item, validation: true };
                                                                        }
                                                                        return item;
                                                                    });
                                                                    setCombinedAnalysisData(updatedData);

                                                                }}>Convalida Esame</button>

                                                            </div>
                                                        );
                                                    }
                                                } else {
                                                    return (
                                                        <strong
                                                            className="customPredictionResultLabel"
                                                            style={{ color: row.predizione ? "" : "black" }}
                                                        >
                                                            {row.predizione ? "DISPONIBILE" : "NON DISPONIBILE"}
                                                        </strong>
                                                    );
                                                }
                                            }
                                        }, 
                                        { field: 'roomNumber', headerName: 'Stanza'}


                                    ]}
                                    rows={combinedAnalysisData ?? []}
                                    slots={{
                                        toolbar: CustomToolbar,
                                        noRowsOverlay: getCustomNoRowsOverlay(isAnalysisLoading ? "Caricamento.." : "Selezionare un Paziente con Esami di Laboratorio per visualizzare i dati"),
                                    }}
                                    getRowClassName={(params) => {
                                            if(params.row.validation === null) return '';
                                            return params.row.validation ? 'valid-row' : 'invalid-row';
                                    }} 
                                    
                                />

                            }

                            {/** VCF ANALYSIS */}    
                            {currentExamType === "LISTA ESAMI GENETICI" && 

                                    <DataGrid
                                    sx={
                                            {
                                                height: 600,

                                                '& .MuiDataGrid-columnHeaders': {
                                                backgroundColor: '#f6f7fb'
                                                },
                                                '& .MuiDataGrid-cell--editing': {
                                                    outline: 'none'  // Remove outline from cell when editing
                                                },
                                                '& .MuiDataGrid-cell:focus': {
                                                    outline: 'none'  // Remove focus outline from cell
                                                },
                                                '& .MuiDataGrid-columnHeaderTitle': {
                                                    fontWeight: 'bold',
                                                    textAlign: 'center',
                                                    flex: 1
                                                },
                                                '& .MuiDataGrid-columnHeaderTitleContainer': {
                                                justifyContent: 'center', 
                                                },
                                                '& .MuiDataGrid-cell': {
                                                textAlign: 'center', 
                                                justifyContent: 'center',
                                                display: 'flex', 
                                                alignItems: 'center'
                                                }
                                            }
                                        }
                                        rowHeight={30}
                                        initialState={{
                                            columns: {
                                            columnVisibilityModel: {
                                                roomNumber: false,
                                            },
                                            },
                                        }}
                                        columns={[
                                            {
                                                field: 'data_analisi',
                                                headerName: 'Data Esame',
                                                flex: 1,
                                            },
                                            { field: 'id_campione', headerName: 'ID Campione', flex: 1 },
                                            { field: 'vcf', headerName: 'VCF', flex: 1, renderCell: renderCellFunctionExpandedVCF },
                                            { field: '', headerName: 'SelcaGenetics', flex: 1, renderCell: ({row}) => {
                                                return <strong onClick={(e) => {e.stopPropagation(); window.open("https://genetics.selca.sicilia.it/analisi", "_blank")}} className="customAnalysisResultLabel" style={{paddingRight:'5px', cursor:'pointer'}}> LINK PIATTAFORMA </strong>
                                            }}


                                        ]}
                                        rows={currentVCF ?? []}
                                        slots={{
                                            toolbar: CustomToolbar,
                                            noRowsOverlay: getCustomNoRowsOverlay(isAnalysisLoading ? "Caricamento.." : "Selezionare un Paziente con Esami Genetici per visualizzare i dati"),
                                        }}
                                        
                                    />                                
                            }   
                    

                    </Card>


                    {/** ANALYSIS HISTORY AREA */}
                    <Card id="analysisHistory">
                        <Title  class="mb-0 marginBottomImportant "><span>{currentChartType}</span> {selectedExamRoom && currentChartType !== "MAPPA ALLARMI" && <span style={{color:"var(--blue-de-france"}}> STANZA {selectedExamRoom}{selectedSubLab && session?.data?.userData?.accountType === "reporter" ? `, LAB. ${selectedSubLab}` : ""}</span>}</Title>
                        {/* {isChartDropdownVisible && 
                                <SimpleDropDownCheck
                                    options={[{code:0, label:"VALORI AMBIENTALI"},{code:1, label:"MAPPA ALLARMI"}]}
                                    onSelect={(chart:any) =>{handleChartSelection(chart.label)}}
                                    position={startChartDropdownPosition}
                                    onClose={() => {seIsChartDropdownVisible(false)}}
                                />} */}
                            {/* <div className="displayFlex" style={{
                                opacity: currentInstrumentData ? 1 : 0,
                                visibility: currentInstrumentData ? 'visible' : 'hidden',
                                transition: 'opacity 0.3s, visibility 0.3s'
                            }}> 

                            <Autocomplete
                                disablePortal
                                id="combo-box-instrument-selection"
                                options={currentInstrumentData?.data?.map((instrument:any) => instrument.type) ?? []}
                                value={selectedInstrumentType ?? null}
                                onChange={(event, value) => {
                                    setSelectedInstrumentType(value);
                                }}
                                sx={{ 
                                    width: 170,
                                    '& .MuiAutocomplete-inputRoot': { 
                                        height: '30px',
                                    },
                                    '& .MuiInputLabel-root': { 
                                        fontSize: '0.8rem', 
                                        top: '-8px',
                                        left: '-3px'
                                    },
                                    '& .MuiOutlinedInput-inputRoot': { 
                                        fontSize: '0.8rem', 
                                        top: '-25px',
                                        left: '-3px'
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField 
                                        {...params} 
                                        label="Instrument Type" 
                                        InputProps={{ ...params.InputProps}}
                                    />
                                )}                            
                            />

                            <Autocomplete
                                disablePortal
                                id="combo-box-uom"
                                options={
                                    currentInstrumentData?.data
                                        ? currentInstrumentData.data
                                              .filter((instrument: any) => instrument.type === selectedInstrumentType)
                                              .map((instrument: any) => instrument.uom)
                                              .flat()
                                        : []
                                }
                                value={selectedInstrumentUOM ?? null}
                                onChange={(event, value) => {
                                    setSelectedInstrumentUOM(value);
                                    setSelectedInstrumentUOM(null);
                                }}
                                sx={{ 
                                    width: 120,
                                    marginLeft: 1,
                                    '& .MuiAutocomplete-inputRoot': { 
                                        height: '30px',
                                    },
                                    '& .MuiInputLabel-root': { 
                                        fontSize: '0.8rem', 
                                        top: '-8px',
                                        left: '-3px'
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField 
                                        {...params} 
                                        label="UoM" 
                                        InputProps={{ ...params.InputProps}}
                                    />
                                )}                            
                            />

                        </div> */}
                        
                        {/** ENVIRONMENTAL CHART */}
                        {selectedAnalysis && currentClippedTimeSeries && currentClippedTimeSeries[40] && currentChartType === "VALORI AMBIENTALI" && currentInstrumentData &&
                            <div onDoubleClick={handleDoubleClick} className="chartContainer">  
                                
                                <ResponsiveContainer width="100%" height="100%">

                                    <LineChart
                                        key={chartKey}
                                        width={chartWidth}
                                        data={currentClippedTimeSeries}
                                        margin={{ top: 20, right: 70, left: 10, bottom: 5 }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="timestamp" tickFormatter={formatTimestamp} />
                                        <YAxis orientation="left"/>
                                        <Tooltip content={<CustomTooltip lineColors={lineColors} />} />
                                        <Legend content={<CustomLegendRenderer lineColors={lineColors} />} />

                                        {/* Dynamically create Line for each instrument model */}
                                        {Object.keys(currentClippedTimeSeries[0] || {})
                                            .filter(key => key !== 'timestamp')
                                            .sort()
                                            .map((measurementType, index) => (
                                                <Line
                                                    dot={false}
                                                    key={measurementType}
                                                    type="monotone"
                                                    dataKey={measurementType}
                                                    stroke={lineColors[index % lineColors.length]}
                                                />
                                            ))
                                        }

                                        <Brush dataKey="timestamp" height={30} stroke="var(--blue-de-france)" />
                                        <ReferenceLine 
                                            x={currentClippedTimeSeries[40].timestamp} 
                                            stroke="var(--blue-de-france)" 
                                            label={{ value: selectedAnalysis.data_prelievo, position: 'insideRight', fill: 'var(--blue-de-france)' }}
                                        />
                                        
                                    </LineChart>

                                </ResponsiveContainer>

                            </div>
                        }
                        
                        {/** ALERT MAP  */}
                        {currentChartType === "MAPPA ALLARMI" && currentInstrumentData?.data && currentInstrumentAlarms && 
                        
                            <div className='imageContainer'>
                                    <div
                                        className={currentInstrumentAlarms ? "imageVisible" : "imageHidden"} 
                                        style={{ 
                                            backgroundImage: `url(${LabsMap})`,
                                            height: '100%',  /* Fill the container */
                                            width: '100%',   /* Fill the container */
                                            backgroundSize: '640px',
                                            backgroundPosition: 'center', /* Center the background image */
                                            marginLeft:'35px',
                                            transition:'0.3s opacity',
                                            opacity: currentInstrumentAlarms ? 1 : 0,
                                            visibility: currentInstrumentAlarms ? 'visible' : 'hidden'
                                        }}></div>
                            {[
                                { id: "1.10", top: '17.6%', right: '28.85%' },
                                { id: "1.11", top: '17.6%', right: '21.75%' },
                                { id: "1.12", top: '41.4%', right: '28.65%' },
                                { id: "1.13", top: '41.5%', right: '21.6%' },
                                { id: "2.10", top: '22.3%', right: '19.3%' },
                                { id: "2.11", top: '16.7%', right: '12.45%' },
                                { id: "2.12", top: '22.3%', right: '5.65%' },
                                { id: "3.10", top: '33.3%', right: '13.75%' },
                                { id: "3.11", top: '32.7%', right: '5.56%' },
                                { id: "3.12", top: '42.65%', right: '5.65%' },
                                // Add more markers if needed
                            ].map(marker => {
                                const instrumentId = marker.id;

                                const alarmSeverity = currentInstrumentAlarms?.color_code as keyof typeof AlertMapIndicator;
                                const markerClass = AlertMapIndicator[alarmSeverity] || 'sensor-marker-clear-small';

                                const isInstrumentPresent = currentInstrumentData.data.some((instr:any) => instr.fullName.includes(instrumentId));

                                return (
                                    <div 
                                        key={marker.id}
                                        id={marker.id}
                                        className={isInstrumentPresent ? markerClass : 'sensor-marker-hidden-small'}
                                        style={{ top: marker.top, right: marker.right, zIndex: '1113' }}
                                        onClick={(e) => handleMarkerClick(marker, e)}
                                    ></div>
                                );
                            })}

                                {selectedMarker && (
                                    <SimpleDropDownCheck
                                        options={selectedMarker.color_code === 0 ? 
                                            [{ code: 1, label: "ALZA Livello di Allarme" }] : 
                                            [{ code: 0, label: "ABBASSA Livello di Allarme" }]}
                                        position={markerDropDownPosition}
                                        onClose={() => setSelectedMarker(null)}
                                        onSelect={() => {}}
                                    />
                                )}

                            </div>
                        
                        }

                            

                        
                    </Card>

                </main>
            </div>
        </Fade>
    );
};
export default React.memo(ReporterDashboard);


// http://localhost:3001/