import React, { useState, useEffect, useContext } from 'react';

import Slide, { SlideProps } from '@mui/material/Slide';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


type TransitionProps = Omit<SlideProps, 'direction'>;
function TransitionRight(props: TransitionProps) {
    return <Slide {...props} direction="left" />;
  }

const CustomSnackbar = (props:any) => {
    // const {isOpened, setIsOpened} = props;
    const [isOpened, setIsOpened] = useState<boolean>(true);
    
    const handleClose = (e:any, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        
        setIsOpened(false)
    }

    setTimeout(() => {
        if(isOpened){
            setIsOpened(false)
        }
    }, props.millis || 2000)
    
    return (
        <>
        <Snackbar
            open={isOpened}
            anchorOrigin={{ vertical: props.vertical || 'bottom', horizontal: props.horizontal || 'right' }}
            autoHideDuration={props.millis || 2000} onClose={handleClose}
            TransitionComponent={TransitionRight}
            style={{position: 'relative'}}
        >
            <Alert onClose={handleClose} severity={props.severity || 'info'} sx={{ width: '100%' }}>
                {props.message}
            </Alert>
        </Snackbar>
        </>
    );
}

export default CustomSnackbar;